import "./header.css";
import "react-gallery-carousel/dist/index.css";
import { useState } from "react";
import { Call } from "@mui/icons-material";

function Info({ apartment }) {
  const [bgcolorHeader, setBgcolorHeader] = useState("header");
  const [showCall, setShowCall] = useState(false);

  window.onscroll = function () {
    changeClassName();
  };

  const changeClassName = () => {
    if (document.documentElement.scrollTop > 170) {
      setBgcolorHeader("header bgcolorHeader");
      setShowCall(true);
    } else {
      setBgcolorHeader("header");
      setShowCall(false);
    }
  };

  return (
    <div className={bgcolorHeader}>
      <div className="header1">
        <div className="header_mobile">
          <div className="nameHeader">
            {apartment?.nameApartment}.
            <span className="addressHeader">ב{apartment?.city}.</span>
          </div>
        </div>

        {showCall && document.documentElement.clientWidth < 600 ? (
          <div className="phone_icon">
            <Call
              style={{
                fontSize: "2.5rem",
                color: "white",
              }}
              className="phone"
              onClick={() => {
                window.open(`tel:${apartment?.phone}`);
              }}
            />
          </div>
        ) : (
          <div
            className="phoneHeader"
            onClick={() => {
              window.open(`tel:${apartment?.phone}`);
            }}
          >
            {apartment?.name} - {apartment?.phone}
          </div>
        )}
      </div>
    </div>
  );
}

export default Info;
