import React, { useContext, useState } from "react";
import "./home.css";
import SearchBoxForHomePage from "../../components/SearchBoxForHomePage/SearchBoxForHomePage";
import { AppContext } from "../../variable-Context";
import ViewListApartments from "../../components/ViewListApartments/ViewListApartments";
import PublishButton from "../../components/PublishButton";
import Modal from "../../components/Modal";
import LandingPage from "../landingPage/popUpLandingPage";

function Home() {
  const { filterApartments, innerWidth } = useContext(AppContext);
  const [isOpenModal, setIsOpenModal] = useState(true);

  return (
    <div className="container_home">
      <div className="publish_button">
        <PublishButton />
      </div>

      <div className="container_responsive">
        {innerWidth > 700 && <SearchBoxForHomePage />}
        {/* {isOpenModal && (
        <Modal setIsOpenModal={setIsOpenModal} content={<LandingPage />} />
      )} */}

        <ViewListApartments
          route={`${process.env.REACT_APP_SERVER_URL}/api/list/filter/`}
          obj={filterApartments}
          messageNoApartments={
            <div>
              לא נמצאו מודעות תואמות לנתוני החיפוש שלך.
              <br />
              יש לשנות את נתוני החיפוש ולנסות שוב.
            </div>
          }
        />
      </div>
    </div>
  );
}

export default Home;
