import "./contact.css";
import React, { useEffect } from "react";
import AddToFavorite from "../addToFavorite/addToFavorite";
import {
  AlternateEmail,
  Language,
  Message,
  Call,
  WhatsApp,
} from "@mui/icons-material";
import gmailIcon from "../../assets/icons8-gmail.svg";

function Contact({ apartment, executeScroll }) {
  return (
    <div className="contact" key={apartment?._id}>
      <Call
        style={{
          fontSize: "5rem",
          color: "blue",
        }}
        className="phone"
        onClick={() => {
          window.open(`tel:${apartment?.phone}`);
        }}
      />

      <WhatsApp
        style={{
          fontSize: "5rem",
          color: "green",
        }}
        className="what"
        onClick={() => {
          window.open(
            `https://wa.me/972${apartment?.phone.replaceAll(
              "-",
              ""
            )}?text=היי, ראיתי את הפרסום שלך "${
              apartment?.nameApartment
            }" באתר "וינפש" ואשמח לשמוע עוד פרטים  `
          );
        }}
      />

      <Message
        className="message"
        style={{ fontSize: "5rem" }}
        onClick={executeScroll}
      />

      <a
        href={`https://mail.google.com/mail/?view=cm&fs=1&to=${apartment?.mail}`}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={gmailIcon}
          alt="gmail"
          style={{
            width: "5rem",
            height: "5rem",
          }}
          className="message"
        />
      </a>

      {apartment?.webSite && (
        <a href={apartment?.webSite} target="_blank" rel="noreferrer">
          <Language
            className="message"
            style={{
              fontSize: "5rem",
              color: "black",
            }}
          />
        </a>
      )}

      <AddToFavorite apartmentForFavorite={apartment} />
    </div>
  );
}

export default Contact;
