import "./Box&Header.css";

function BoxHeader({ width, content, label, border }) {
  return (
    <div className="Box_Header" style={{ width: width }}>
      <div className="Header">{label}</div>
      <div className="Box" style={border && { borderWidth: border }}>
        {content}{" "}
      </div>
    </div>
  );
}

export default BoxHeader;
