import "./PageNumberBar.css";
import React, { useState, useRef } from "react";
import {
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
} from "@mui/icons-material";
const PageNumberBar = ({
  data,
  countAdsForPage,
  startIndex,
  endIndex,
  setStartIndex,
  setEndIndex,
  executeScrollTop,
}) => {
  const [page, setPage] = useState(1);

  const hiddenButtonRef = useRef(null);

  setStartIndex((page - 1) * countAdsForPage);
  setEndIndex(startIndex + countAdsForPage);
  const numbersPage = [];
  const countPage = data.length / countAdsForPage;
  const roundedNum = Math.ceil(countPage);

  for (let i = 1; i <= roundedNum; i++) {
    numbersPage.push(i);
  }

  return (
    <div className="div_bar_page_number">
      <div className="div_btn_arrow">
        <button
          disabled={startIndex === 0 ? true : false}
          style={startIndex === 0 ? { opacity: "0.6", cursor: "auto" } : null}
          onClick={async () => {
            setPage(page - 1);
            hiddenButtonRef.current.scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "end",
            });
            await executeScrollTop();
          }}
          className="btn_page_number btn_page_arrow"
        >
          <KeyboardDoubleArrowRight style={{ fontSize: "2.5rem" }} />
        </button>
      </div>

      <div className="div_btn_numbers">
        {numbersPage.map((item, index) => (
          <button
            ref={item === endIndex / countAdsForPage ? hiddenButtonRef : null}
            key={index}
            onClick={() => {
              setPage(item);
              executeScrollTop();
            }}
            style={
              item === endIndex / countAdsForPage
                ? {
                    background: "var(--blue)",
                    color: "var(--white)",
                  }
                : null
            }
            className="btn_page_number"
          >
            {item}
          </button>
        ))}
      </div>

      <div className="div_btn_arrow">
        <button
          disabled={endIndex >= data.length ? true : false}
          style={
            endIndex >= data.length ? { opacity: "0.6", cursor: "auto" } : null
          }
          onClick={() => {
            setPage(page + 1);
            hiddenButtonRef.current.scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "start",
            });
            executeScrollTop();
          }}
          className="btn_page_number btn_page_arrow"
        >
          <KeyboardDoubleArrowLeft style={{ fontSize: "2.5rem" }} />{" "}
        </button>
      </div>
    </div>
  );
};
export default PageNumberBar;
