import "./Footer.css";
import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../variable-Context";
import Logo from "../Logo/Logo";
import { MailOutline, RateReview, VerifiedUser } from "@mui/icons-material";

function Footer() {
  return (
    <div className="footer">
      <div className="container_responsive footer_content">
        <div className="footer_div_logo">
          <Logo
            titleColor="var(--white)"
            fontSizeText="7rem"
            fontSizeTitle="1.6rem"
            sizeImg="5rem"
          />
        </div>
        <div className="footer_div_content">
          <div className="header_footer_div_content">ניווט</div>
          <div className="footer_div_links">
            <a href="/">מודעות דירות להשכרה</a>
            <a href="/searchApartment">מודעות חיפוש דירות</a>
            <a href="/BigPlaces">מתחמים גדולים</a>
            <a href="/login/">הרשמה / התחברות</a>
          </div>
        </div>
        <div className="footer_div_content">
          <div className="header_footer_div_content">צור קשר </div>
          <div className="footer_div_links">
            <a href="/ContactUs/">
              {" "}
              <RateReview className="icon_footer" />
              כתבו לנו
            </a>
            <a
              href={`https://mail.google.com/mail/?view=cm&fs=1&to=${process.env.REACT_APP_MANAGEMENT_EMAIL}`}
              target="_blank"
              rel="noreferrer"
              style={{ display: "flex", alignItems: "center" }}
            >
              <MailOutline className="icon_footer" />
              {process.env.REACT_APP_MANAGEMENT_EMAIL}
            </a>
            <a href="/privacyPolicy">
              {" "}
              <VerifiedUser className="icon_footer" />
              תקנון האתר
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
