import ListRentApartment from "../../components/ListRentApartment&delete&edit/ListRentApartment";
import ListSearchApartment from "../../components/ListSearchApartment&delete&edit/ListSearchApartment";
import Tabs from "../../components/tabs/tabs";

const ListApartment = () => {
  return (
    <Tabs
      label0="מודעות לאישור"
      label1="מודעות פעילות"
      label2="מודעות לא פעילות"
      label3="מודעות חיפוש דירות"
      Tab1={
        <ListRentApartment
          url={`${process.env.REACT_APP_SERVER_URL}/api/allApartmentsForEditDelete/`}
          show={"0"}
        />
      }
      Tab2={
        <ListRentApartment
          url={`${process.env.REACT_APP_SERVER_URL}/api/allApartmentsForEditDelete/`}
          show={1}
        />
      }
      Tab3={
        <ListRentApartment
          url={`${process.env.REACT_APP_SERVER_URL}/api/allApartmentsForEditDelete/`}
          show={2}
        />
      }
      Tab4={
        <ListSearchApartment
          url={`${process.env.REACT_APP_SERVER_URL}/api/listPostSearch`}
        />
      }
    />
  );
};
export default ListApartment;
