import "./Input_select_button.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";

export const Input = (props) => {
  const {
    label,
    placeholder,
    type,
    name,
    onChange,
    formikErr,
    width,
    content,
    value,
    defaultValue,
  } = props;

  return (
    <div className="div_input" style={{ width: width }}>
      {label ? <label className="label_input">{label}</label> : null}
      <input
        className="input"
        placeholder={placeholder}
        type={type ? type : "text"}
        name={name}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
      />
      {content}
      <div className="div_err_input">{formikErr}</div>
    </div>
  );
};

export const Button = (props) => {
  const { title, padding, onClick, borderRadius, type, disabled, width } =
    props;

  return (
    <button
      className={!disabled ? "btn_component" : "btn_component disabled"}
      style={{ padding: padding, borderRadius: borderRadius, width: width }}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export const Checkbox = (props) => {
  const { name, onChange, label, checked, formikErr, value } = props;

  return (
    <div>
      <div className="checkbox">
        <input
          type="checkbox"
          name={name}
          onChange={onChange}
          checked={checked}
          value={value}
        />
        <label>{label}</label>
      </div>
      {formikErr ? <div className="div_err_input">{formikErr}</div> : null}
    </div>
  );
};

export const Select = (props) => {
  const {
    label,
    name,
    onChange,
    options,
    formikErr,
    width,
    defaultValue,
    disabledSelect,
    value,
    placeholder,
    selected,
  } = props;

  return (
    <div className="div_input" style={{ width: width }}>
      {label ? (
        <label
          className="label_input"
          id={disabledSelect ? "disabledSelect" : ""}
        >
          {label}
        </label>
      ) : null}

      <select
        disabled={disabledSelect}
        className="input"
        id="width-select"
        name={name}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
      >
        <option value={""}>{placeholder ? placeholder : null} </option>
        {options?.map((item, index) => (
          <option
            value={item.value}
            key={index}
            selected={item.title === selected}
          >
            {item.title}
          </option>
        ))}
      </select>
      <div className="div_err_input">{formikErr}</div>
    </div>
  );
};
export const InputSelect = (props) => {
  const {
    label,
    name,
    onChange,
    placeholder,
    formikErr,
    width,
    defaultValue,
    disabledInputSelect,
    value,
    content,
    type,
    onClick,
  } = props;

  return (
    <div className="div_input" style={{ width: width }}>
      {label ? (
        <label
          className="label_input"
          id={disabledInputSelect ? "disabledSelect" : ""}
        >
          {label}
        </label>
      ) : null}
      <input
        className="input"
        placeholder={placeholder}
        type={type ? type : "text"}
        name={name}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        onClick={onClick}
        disabled={disabledInputSelect}
      />
      <div className="icon_arrow_down" onClick={onClick}>
        <ExpandMoreIcon
          sx={{
            fontSize: "20px",
            color: "black",
          }}
        />
      </div>
      <div className="div_options">{content}</div>
      <div className="div_err_input">{formikErr}</div>
    </div>
  );
};
export const SelectCheckbox = (props) => {
  const {
    label,
    name,
    onChange,
    placeholder,
    formikErr,
    width,
    defaultValue,
    disabledInputSelect,
    options,
    type,
    checked,
    setChecked,
    state,
    setState,
  } = props;

  const [showOption, setShowOption] = useState(false);
  const handleBlur = () => setShowOption(false);
  return (
    <div className="div_input" style={{ width: width }}>
      {label ? (
        <label
          className="label_input"
          id={disabledInputSelect ? "disabledSelect" : ""}
        >
          {label}
        </label>
      ) : null}
      <div
        className="input"
        placeholder={placeholder}
        type={type ? type : "text"}
        name={name}
        onChange={onChange}
        disabled={disabledInputSelect}
        onBlur={handleBlur}
        onClick={() => setShowOption(!showOption)}
        tabIndex={0}
        style={{ height: showOption ? "auto" : "3.8rem", minHeight: "3.8rem" }}
      >
        <div className="div_show_checked">
          {defaultValue
            ? defaultValue.map((item) => (
                <div className="div_checked_title">{item}</div>
              ))
            : ""}
        </div>
        <div
          className="icon_arrow_down"
          onClick={() => {
            setShowOption(!showOption);
          }}
        >
          <ExpandMoreIcon
            sx={{
              fontSize: "20px",
              color: "black",
            }}
          />
        </div>
      </div>
      {showOption && (
        <div
          className="div_options"
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        >
          {options.map((option, index) => (
            <Checkbox
              key={index}
              label={option.title}
              value={option.value}
              checked={checked?.map((check) => check).includes(option.value)}
              onChange={(event) => {
                let test = checked.find((e) => e === event.target.value);
                if (!test) {
                  checked.push(event.target.value);
                  setState({
                    ...state,
                    [name]: checked,
                  });
                } else {
                  let filter = checked.filter((e) => e !== event.target.value);
                  setChecked(filter);
                  setState({
                    ...state,
                    [name]: filter,
                  });
                }
              }}
            />
          ))}
        </div>
      )}
      <div className="div_err_input">{formikErr}</div>
    </div>
  );
};

export const DivOpenHover = (props) => {
  const { label, width, content } = props;

  return (
    <div style={{ width: width }} className="div_open_hover">
      {label}
      <div className="content_div_open_hover"> {content}</div>
    </div>
  );
};

export const TextArea = (props) => {
  const {
    label,
    onInput,
    placeholder,
    formikErr,
    width,
    height,
    content,
    contentEditable,
    name,
  } = props;

  return (
    <div className="div_input" style={{ width: width }}>
      <label className="label_input">{label}</label>

      <div
        className="input"
        id="textarea"
        style={{ height: height }}
        onInput={onInput}
        contentEditable={contentEditable}
        placeholder={placeholder}
        suppressContentEditableWarning="true"
        name={name}
      >
        {content}
      </div>
      <div className="div_err_input">{formikErr}</div>
    </div>
  );
};
