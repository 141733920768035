import React, { useContext } from "react";
import "./FavoriteInHeader.css";
import { AppContext } from "../../variable-Context";
import { FavoriteBorder, Favorite } from "@mui/icons-material/";
import { Link } from "react-router-dom";
import { DivOpenHover } from "../Input_select_button/Input_select_button";
import AddToFavorite from "../addToFavorite/addToFavorite";
import Logo from "../Logo/Logo";

function FavoriteInHeader() {
  const {
    detailsUsers,
    responseFavoriteApartments,
    responseFavoriteReplacementSearch,
    innerWidth,
  } = useContext(AppContext);

  const amountFavorite =
    responseFavoriteApartments?.data?.length +
    responseFavoriteReplacementSearch?.data?.length;

  const favoriteInHeader = (
    <Link className="div_label_favorite" to={"/myfavorite/"}>
      {innerWidth > 701 && (
        <div style={{ textAlign: "center" }}>מודעות שאהבתי </div>
      )}
      <div className="div_icon_favorite">
        {amountFavorite > 0 ? (
          <Favorite className="icon_favorite" />
        ) : (
          <FavoriteBorder className="icon_favorite" />
        )}
        {amountFavorite > 0 && (
          <div className="amount_favorite">{amountFavorite}</div>
        )}
      </div>
    </Link>
  );

  return (
    <div className="div_favorite_in_header">
      {innerWidth < 701 ? (
        favoriteInHeader
      ) : (
        <DivOpenHover
          label={favoriteInHeader}
          content={
            <div className="div_list_favorite_in_header scroll_designed">
              {!detailsUsers ? (
                <div className="msg_no_favorite">
                  יש להתחבר <br />
                  על מנת לראות את המודעות שאהבת
                  <br />
                  <a href="/login/" style={{ textDecoration: "underline" }}>
                    התחברות{" "}
                  </a>
                </div>
              ) : responseFavoriteApartments?.data?.length < 1 ? (
                <div className="msg_no_favorite">
                  אין לך מועדפים &#128542;
                  <br />
                  ניתן להוסיף ע"י לחיצה על{" "}
                  <FavoriteBorder style={{ color: "red" }} /> הנמצא בכל מודעה.
                  <br /> כך תוכלו למצוא תמיד את המודעות שאהבתם.
                </div>
              ) : responseFavoriteApartments?.status === 200 &&
                responseFavoriteApartments?.data?.length > 0 ? (
                responseFavoriteApartments?.data?.map((list) => (
                  <div key={list._id}>
                    <Link
                      to={"/apartmentShow/" + list._id}
                      target="_blank"
                      className="box_apartment_favorite"
                    >
                      <div
                        className="div_add_favorite"
                        onClick={(event) => event.preventDefault()}
                      >
                        <AddToFavorite
                          apartmentForFavorite={list}
                          fontSize="2rem !important"
                        />
                      </div>
                      {list.images.length > 0 ? (
                        <img
                          className="img_apartment_favorite"
                          src={list.images[0]}
                          alt=""
                        ></img>
                      ) : (
                        <div className="img_apartment_favorite">
                          <Logo
                            fontSizeText="4rem"
                            fontSizeTitle="0.8rem"
                            sizeImg="3rem"
                          />
                        </div>
                      )}

                      <div className="info_apartment_favorite">
                        <div className="name_apartment_favorite">
                          {list.nameApartment}
                        </div>
                        <div className="city_apartment_favorite">
                          {" "}
                          {list.city}.
                        </div>
                        <div className="phone_apartment_favorite">
                          {list.phone}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : responseFavoriteApartments?.status === 500 ||
                responseFavoriteApartments?.status === 400 ? (
                <div>
                  שגיאת נתונים.
                  <br />
                  נסו שוב במועד מאוחר יותר.
                </div>
              ) : null}
            </div>
          }
        />
      )}
    </div>
  );
}

export default FavoriteInHeader;
