import "./HamburgerAnimation.css";

function HamburgerAnimation({ hamburger }) {
  return (
    <div className="hamburger_animation">
      <div className={`line line1 ${hamburger ? "hamburger" : "close"}`} />
      <div className={`line line2 ${!hamburger ? "hamburger" : "close"}`} />
      <div className={`line line3 ${hamburger ? "hamburger" : "close"}`} />
    </div>
  );
}

export default HamburgerAnimation;
