import React, { useState, useContext } from "react";
import AddEditSearchApartment from "../Add_&_Edit_SearchApartment/Add_&_Edit_SearchApartment";

const EditApartment = ({ itemForEdit, setIsOpenModal }) => {
  return (
    <div>
      <AddEditSearchApartment
        itemForEdit={itemForEdit}
        setIsOpenModal={setIsOpenModal}
      />
    </div>
  );
};
export default EditApartment;
