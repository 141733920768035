import React, { useEffect, useState } from "react";
import "./Carousel.css";
import Arrow from "./arrow/arrow";

const Carousel = ({ content, moveScreen, setMoveScreen }) => {
  const [screen, setScreen] = useState(1);
  const [animation, setAnimation] = useState();

  useEffect(() => {
    if (moveScreen !== undefined) {
      setScreen(moveScreen);
      setAnimation("animation_forward");
    }
  }, [moveScreen]);

  return (
    <div className="carousel">
      <div className="window_carousel">
        {content
          .sort((a, b) => (b.id > a.id ? -1 : 1))
          .map((item, index) => (
            <div
              className={`content_carousel ${
                screen === index + 1 ? animation : "opacity_background"
              }`}
              key={index}
            >
              {item.content}
            </div>
          ))}
      </div>

      <div className="div_buttons_carousel">
        <div
          className="button_carousel buttons_carousel_up"
          onClick={() => {
            setMoveScreen(undefined);
            if (screen < content.length) {
              setScreen(screen + 1);
              setAnimation("animation_forward");
            } else if (screen === content.length) {
              setScreen(1);
              setAnimation("animation_forward");
            }
          }}
        >
          <Arrow />
        </div>
        <div
          className="button_carousel"
          onClick={() => {
            setMoveScreen(undefined);
            if (screen > 1) {
              setScreen(screen - 1);
              setAnimation("animation_backwards");
            } else if (screen === 1) {
              setScreen(content.length);
              setAnimation("animation_backwards");
            }
          }}
        >
          <Arrow />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
