import "./landingPage.css";
import React, { useContext } from "react";
import { Button } from "../../components/Input_select_button/Input_select_button";
import { AppContext } from "../../variable-Context";

const ButtonMoveLogin = () => {
  const { innerWidth } = useContext(AppContext);
  return (
    <Button
      title={
        <div className="container_arrow_for_login">
          {" "}
          פרסום מודעה <div className="arrow_for_login">{`>`}</div>
          <div className="arrow_for_login">{`>`}</div>
          <div className="arrow_for_login">{`>`}</div>
        </div>
      }
      padding={innerWidth > 700 ? "0.4rem 30px" : "0.4rem 10px"}
      borderRadius="10px"
      onClick={() => {
        window.open("https://www.vainafash.co.il/login/");
      }}
    />
  );
};

export default ButtonMoveLogin;
