import "./ApartmentShowComponent.css";
import Location from "./location";
import CalendarComponent from "../CalendarComponent/CalendarComponent";
import SliderImage from "../sliderImage";
import Info from "./info";
import Header from "./header/header";
import BoxHeader from "../Box&Header/Box&Header";
import { AppContext } from "../../variable-Context";
import { useContext } from "react";

function ApartmentShow({ apartmentShow }) {
  const { innerWidth } = useContext(AppContext);

  return (
    <div className="apartmentShowComponent">
      <Header apartmentShow={apartmentShow} />

      {apartmentShow?.images?.length > 0 ? (
        <div className="div_images_slider">
          <SliderImage
            listImages={apartmentShow}
            isAutoPlaying={true}
            hasSizeButton="bottomRight"
            hasThumbnails={true}
          />
        </div>
      ) : null}

      <BoxHeader
        width={"100%"}
        content={<Info apartmentShow={apartmentShow} />}
        label={"מידע"}
      />

      <BoxHeader
        width={"100%"}
        content={
          <div
            style={{
              width: innerWidth > 600 ? "62%" : "92%",
              margin: "0 auto",
            }}
          >
            <CalendarComponent dateBusy={apartmentShow?.dateBusy} />
          </div>
        }
        label={"תאריכים פנויים"}
      />

      <BoxHeader
        width={"100%"}
        content={<Location apartmentShow={apartmentShow} />}
        label={"מפת האיזור"}
      />
    </div>
  );
}

export default ApartmentShow;
