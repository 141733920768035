import "./arrow.css";
import { AppContext } from "../../../variable-Context";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useContext } from "react";

const Arrow = () => {
  const { innerWidth } = useContext(AppContext);
  return (
    <div className="container_arrow">
      {innerWidth > 600 ? (
        <>
          <div className="arrow_animate"></div>
          <div className="arrow_animate"></div>
          <div className="arrow_animate"></div>
        </>
      ) : (
        <DoubleArrowIcon
          className="arrow_small_screen"
          style={{ fontSize: "3rem" }}
        />
      )}
    </div>
  );
};
export default Arrow;
