import "./myFavorite-page.css";
import React, { useContext } from "react";
import { AppContext } from "../../variable-Context";
import { FavoriteBorder, SwapHoriz } from "@mui/icons-material/";
import MaintenanceScreen from "../../components/MaintenanceScreen/MaintenanceScreen";
import Baner from "../../components/baner";
import { Link } from "react-router-dom";
import AddToFavorite from "../../components/addToFavorite/addToFavorite";
import Logo from "../../components/Logo/Logo";
import Modal from "../../components/Modal";
import SearchApartmentShowComponent from "../../components/SearchApartmentShowComponent/SearchApartmentShowComponent";
import { useState } from "react";

function MyFavoritePage() {
  const {
    detailsUsers,
    responseFavoriteApartments,
    responseFavoriteReplacementSearch,
  } = useContext(AppContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState();

  const messageNoApartments = (
    <div className="message_no_apartments">
      אין לך מועדפים &#128542;
      <br />
      <br /> ניתן להוסיף ע"י לחיצה על <FavoriteBorder className="iconError" />{" "}
      הנמצא בכל מודעה.
      <br />
      <br /> כך תוכלו למצוא תמיד את המודעות שאהבתם.
    </div>
  );

  return (
    <div className="container_responsive">
      <div className="title_my_favorite">מודעות שאהבתי</div>
      {!detailsUsers ? (
        <MaintenanceScreen
          content={
            <div>
              יש להתחבר על מנת לראות את המודעות שאהבת
              <br />
              <br />
              להתחברות{" "}
              <a href="/login/" style={{ textDecoration: "underline" }}>
                לחץ כאן
              </a>{" "}
            </div>
          }
        />
      ) : responseFavoriteApartments?.data?.length === 0 &&
        responseFavoriteReplacementSearch?.data?.length === 0 ? (
        messageNoApartments
      ) : (
        <div className="div_list_page_favorite">
          {responseFavoriteApartments?.data?.length > 0 && (
            <>
              <Baner content="דירות להשכרה" />
              {responseFavoriteApartments?.data?.map((item) => (
                <div key={item._id}>
                  <Link
                    to={"/apartmentShow/" + item._id}
                    className="box_apartment_page_favorite box_responsive_page_favorite"
                  >
                    <div
                      className="div_add_favorite"
                      onClick={(event) => event.preventDefault()}
                    >
                      <AddToFavorite
                        apartmentForFavorite={item}
                        fontSize="2rem !important"
                      />
                    </div>
                    {item.images.length > 0 ? (
                      <img
                        className="img_apartment_page_favorite"
                        src={item.images[0]}
                        alt=""
                      ></img>
                    ) : (
                      <div className="img_apartment_page_favorite">
                        <Logo
                          fontSizeText="4rem"
                          fontSizeTitle="0.8rem"
                          sizeImg="3rem"
                        />
                      </div>
                    )}

                    <div className="info_apartment_page_favorite">
                      <div className="name_apartment_page_favorite">
                        {item.nameApartment}
                      </div>
                      <div className="city_apartment_page_favorite">
                        {" "}
                        {item.area}. {item.city}.
                      </div>
                      <div className="phone_apartment_page_favorite">
                        {item.rooms} חדרים. עד {item.beds} מיטות.
                      </div>
                    </div>
                    <div className="info_contact_page_favorite">
                      <div className="name_apartment_page_favorite">
                        {item.name}
                      </div>
                      <div className="phone_apartment_page_favorite">
                        {item.phone}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </>
          )}

          {responseFavoriteReplacementSearch?.data?.length > 0 && (
            <>
              <Baner content="חיפוש דירות / החלפה" />
              {responseFavoriteReplacementSearch?.data?.map((item) => (
                <div key={item._id} className="box_responsive_page_favorite">
                  <div
                    className="div_add_favorite"
                    onClick={(event) => event.preventDefault()}
                  >
                    <AddToFavorite
                      apartmentForFavorite={item}
                      fontSize="2rem !important"
                    />
                  </div>
                  <div
                    onClick={() => {
                      setContentModal(item);
                      setIsOpenModal(true);
                    }}
                    className="box_replacementSearch_page_favorite"
                  >
                    <div className="title_replacementSearch_page_favorite">
                      {item.model === "1"
                        ? "השכרה"
                        : item.model === "2"
                        ? "החלפה"
                        : "השכרה / החלפה"}
                    </div>
                    <div className="info_replacementSearch_page_favorite">
                      <div className="info_apartment_page_favorite">
                        <div className="name_apartment_page_favorite">
                          {item.areaSearch}
                        </div>
                        <div className="city_apartment_page_favorite">
                          {" "}
                          {item.citySearch.map((item) => item + ", ")}
                        </div>
                        <div className="phone_apartment_page_favorite">
                          {item.roomsSearch} חדרים. עד {item.bedsSearch} מיטות.
                        </div>
                      </div>

                      {["2", "3"].includes(item.model) ? (
                        <div className="div_icon_replacementSearch">
                          <SwapHoriz style={{ fontSize: "3rem" }} />
                        </div>
                      ) : (
                        <div className="div_icon_replacementSearch" />
                      )}
                      <div className="info_apartment_page_favorite">
                        {["2", "3"].includes(item.model) ? (
                          <>
                            <div className="name_apartment_page_favorite">
                              {item.areaChange}
                            </div>
                            <div className="city_apartment_page_favorite">
                              {" "}
                              {item.cityChange}. רח' {item.streetChange}
                            </div>
                            <div className="phone_apartment_page_favorite">
                              {item.roomsChange} חדרים. עד {item.bedsChange}{" "}
                              מיטות.
                            </div>
                          </>
                        ) : (
                          <div className="info_apartment_page_favorite" />
                        )}
                      </div>
                      <div className="info_contact_page_favorite">
                        <div className="name_apartment_page_favorite"></div>
                        <div className="name_apartment_page_favorite">
                          {item.name}
                        </div>
                        <div className="phone_apartment_page_favorite">
                          {item.phone}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
      {isOpenModal && (
        <Modal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          content={
            <SearchApartmentShowComponent
              data={contentModal}
              sendMessage={true}
            />
          }
        />
      )}
    </div>
  );
}

export default MyFavoritePage;
