import React, { useState } from "react";
import "./users.css";
import { GetDataLoading } from "../../components/getData";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Accordion from "../../components/Accordion";

function Users() {
  const [users, setUsers] = useState([]);

  const icon = (test) => {
    return test ? (
      <CheckCircleIcon
        style={{
          fontSize: "2.5rem",
          color: "green",
        }}
      />
    ) : (
      <CancelIcon
        style={{
          fontSize: "2.5rem",
          color: "red",
        }}
      />
    );
  };

  return (
    <div style={{ fontSize: "1.3rem" }}>
      כמות משתמשים: {users?.length}
      <GetDataLoading
        route={`${process.env.REACT_APP_SERVER_URL}/api/listUsers/`}
        data={setUsers}
        content={users
          .sort((a, b) => (new Date(a.date) > new Date(b.date) ? -1 : 1))
          .map((item) => (
            <Accordion
              key={item._id}
              boxheader={
                <div style={{ display: "flex" }}>
                  <div className="header_box_users">
                    <div>{new Date(item.date).toLocaleDateString()}</div>
                    <div>{item.nameUser}</div>
                    <div>{item.mailUser}</div>
                    <div>{item.phoneUser}</div>
                  </div>
                  <div style={{ width: "10%" }}>
                    {icon(item.msgSearchApartment)}
                  </div>
                  <div style={{ width: "10%" }}>
                    {icon(item.msgSaleApartment)}
                  </div>
                </div>
              }
              box={
                <div>
                  <div>{item.uidFirebase}</div>
                  <div className="box_users">
                    {item.citiesSearchApartment?.length > 0 &&
                      item.citiesSearchApartment.map((item, i) => (
                        <span key={i}>{item}, </span>
                      ))}
                  </div>
                  <div>
                    {item.citiesSaleApartment?.length > 0 &&
                      item.citiesSaleApartment.map((item, i) => (
                        <span key={i}>{item}, </span>
                      ))}
                  </div>
                </div>
              }
            />
          ))}
      />
    </div>
  );
}

export default Users;
