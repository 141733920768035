import "./SearchBoxForHomePage.css";
import React, { useState, useEffect, useContext } from "react";
import {
  Input,
  Select,
  SelectCheckbox,
  TextArea,
} from "../Input_select_button/Input_select_button";
import { Search } from "@mui/icons-material";
import Modal from "../Modal";
import { AppContext } from "../../variable-Context";
import { PostToServer } from "../getData";
import { listCitiesByArea } from "../ListCitiesByArea";

function SearchBoxForHomePage() {
  const { setFilterApartments, innerWidth } = useContext(AppContext);
  const [listCityForSearch, setListCityForSearch] = useState();
  const [searchApartments, setSearchApartments] = useState();
  const [cityFromArea, setCityFromArea] = useState([]);
  const [selectCity, setSelectCity] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    PostToServer(
      `${process.env.REACT_APP_SERVER_URL}/api/list/filter/`,
      null,
      setListCityForSearch
    );
  }, []);

  const onchange = (e) => {
    setSearchApartments({
      ...searchApartments,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    listCitiesByArea(
      listCityForSearch,
      "city",
      searchApartments,
      "area",
      setCityFromArea
    );
  }, [searchApartments, listCityForSearch]);

  const searchTime = [
    { title: `שבתות / סופ"ש`, value: "1" },
    { title: "חגים", value: "2" },
    { title: "בין הזמנים", value: "3" },
    { title: `כל השנה`, value: "4" },
  ];

  const area = [
    { title: "דירות בכל הארץ", value: "" },
    { title: "דירות באיזור הצפון", value: "צפון" },
    { title: "דירות באיזור המרכז", value: "מרכז" },
    { title: "דירות באיזור הדרום", value: "דרום" },
    { title: "דירות באיזור ירושלים", value: "ירושלים" },
  ];

  const componentSearch = (
    <div className="Search_Component">
      <div className="Header_Search_Component">איפה תרצו לנפוש?</div>
      <div className="Inputs_Search">
        <Input
          name={"nameApartment"}
          label={"חיפוש לפי שם"}
          onChange={onchange}
          width={innerWidth > 700 ? "17%" : "80%"}
          defaultValue={searchApartments?.nameApartment}
        />
        <Select
          name={"area"}
          label={"בחר איזור בארץ"}
          onChange={(e) => {
            setSelectCity([]);
            setSearchApartments({
              ...searchApartments,
              [e.target.name]: e.target.value,
              city: "",
            });
          }}
          options={area}
          width={innerWidth > 700 ? "17%" : "80%"}
        />
        <SelectCheckbox
          name={"city"}
          label={"בחר עיר"}
          options={cityFromArea}
          width={innerWidth > 700 ? "17%" : "80%"}
          defaultValue={selectCity}
          checked={selectCity}
          setChecked={setSelectCity}
          state={searchApartments}
          setState={setSearchApartments}
        />
        <TextArea
          label={"כמות מיטות"}
          width={innerWidth > 700 ? "17%" : "80%"}
          height={"2.4rem"}
          content={
            <div className="div_input_beds">
              מ-
              <input
                className="input_beds"
                onChange={(e) => {
                  setSearchApartments({
                    ...searchApartments,
                    minBeds: e.target.value,
                  });
                }}
                defaultValue={searchApartments?.minBeds}
              />{" "}
              עד-
              <input
                className="input_beds"
                onChange={(e) => {
                  setSearchApartments({
                    ...searchApartments,
                    maxBeds: e.target.value,
                  });
                }}
                defaultValue={searchApartments?.maxBeds}
              />
            </div>
          }
          contentEditable={false}
        />
        <Select
          name={"time"}
          label={"מתי תרצו לנפוש?"}
          onChange={onchange}
          options={searchTime}
          width={innerWidth > 700 ? "17%" : "80%"}
          defaultValue={searchApartments?.time}
        />
        <div>
          <Search
            style={{
              fontSize: "5rem",
              color: "var(--blue)",
              cursor: "pointer",
            }}
            onClick={() => {
              setFilterApartments(searchApartments);
              setIsOpenModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );

  return innerWidth > 701 ? (
    componentSearch
  ) : (
    <div className="div_icon_search_mobile">
      <Search
        style={{
          fontSize: "4rem",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => {
          setSearchApartments();
          setSelectCity([]);
          setCityFromArea([]);
          setIsOpenModal(true);
        }}
      />

      {isOpenModal && (
        <Modal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          content={componentSearch}
        />
      )}
    </div>
  );
}

export default SearchBoxForHomePage;
