import "./header.css";
import { Call } from "@mui/icons-material";

function Info({ data }) {
  return (
    <div className="header header_mobile">
      {/* <div className="header_mobile"> */}

      <div className="nameHeader">
        {data?.model === "1"
          ? "השכרה"
          : data?.model === "2"
          ? "החלפה"
          : "השכרה / החלפה"}
        ,<span className="addressHeader">ב{data?.areaSearch}.</span>
        {/* </div> */}
      </div>

      <div
        className="addressHeader"
        onClick={() => {
          window.open(`tel:${data?.phone}`);
        }}
      >
        {data?.name} - {data?.phone}
        <Call
          style={{
            fontSize: "2.5rem",
            color: "green",
            marginInlineStart: "10px",
          }}
        />
      </div>
    </div>
  );
}

export default Info;
