import React, { useState, useContext } from "react";
import AddEditApartment from "../Add_&_Edit_Apartment/Add_&_Edit_Apartment";

const EditApartment = ({ itemForEdit, setIsOpenModal, render, setRender }) => {
  return (
    <div>
      <AddEditApartment
        itemForEdit={itemForEdit}
        setIsOpenModal={setIsOpenModal}
        render={render}
        setRender={setRender}
      />
    </div>
  );
};
export default EditApartment;
