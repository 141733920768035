import "./ScreenReplacementSearch.css";
import imgReplacementSearch from "./replacementSearch.png";

const ScreenReplacementSearch = () => {
  return (
    <div className="screen_replecment_search scroll_designed">
      <div className="text_screen_replecment_search ">
        <div>
          אם אתם מחפשים דירת אירוח מדהימה לשבת, <br />
          לחג או פשוט כדי להנות מחופשה נעימה, <br />
          אנחנו כאן כדי לעזור לכם. <br />
          באתר "וינפש" תוכלו לפרסם את בקשתכם <br /> ולקבל הצעות מתאימות שיתאימו
          לצרכים שלכם.{" "}
        </div>
        <div>
          מעוניינים להחליף דירה בבין הזמנים? <br />
          אתם גם במקום הנכון. <br /> באמצעות האתר שלנו, תוכלו לפרסם את בקשתכם{" "}
          <br /> ולמצוא דיירים מתאימים להחלפת דירה <br />
          על פי הזמנים שמתאימים לכם.
        </div>
        <div>
          אל תחמיצו את ההזדמנות לפרסם את בקשתכם ולמצוא את הדירה המושלמת <br />{" "}
          או ההחלפה המתאימה דרך "וינפש".
        </div>
      </div>
      <div className="img_screen_replecment_search ">
        <img src={imgReplacementSearch} alt="postApartment" />
      </div>
    </div>
  );
};

export default ScreenReplacementSearch;
