import "./contact.css";
import { parsePhoneNumber } from "libphonenumber-js";
import { Input } from "../../Input_select_button/Input_select_button";

function Contact({ setApartment, apartment, formik, onchange }) {
  return (
    <div className="div-all-input div_contact">
      <Input
        label={"שם איש הקשר"}
        name={"name"}
        value={apartment?.name}
        onChange={onchange}
        formikErr={formik.errors.name}
        width={"90%"}
      />

      <Input
        label={"מייל"}
        name={"mail"}
        value={apartment?.mail}
        onChange={onchange}
        formikErr={formik.errors.mail}
        width={"90%"}
      />

      <Input
        label={"טלפון"}
        name={"phone"}
        value={apartment?.phone}
        onChange={(e) => {
          setApartment({
            ...apartment,
            [e.target.name]:
              e.target.value.length > 1
                ? parsePhoneNumber(e.target.value, "IL").formatNational()
                : "0",
          });
        }}
        formikErr={formik.errors.phone}
        width={"90%"}
      />
    </div>
  );
}

export default Contact;
