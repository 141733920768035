import AddApartment from "../../components/Add_&_Edit_Apartment/Add_&_Edit_Apartment";
import SearchApartment from "../../components/Add_&_Edit_SearchApartment/Add_&_Edit_SearchApartment";
import TabsButton from "../../components/TabsButton";

const AddPost = () => {
  const content = [
    {
      id: 1,
      title: "פרסם דירה",
      component: <AddApartment />,
    },
    {
      id: 2,
      title: "מחפש דירה",
      component: <SearchApartment />,
    },
    // {
    //   id: 3,
    //   title: "מתחמי אירוח",
    //   component: <AddApartment />,
    // },
  ];

  return <TabsButton content={content} />;
};
export default AddPost;
