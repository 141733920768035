import { useState, useContext } from "react";
import SelectNavigate from "./SelectNavigate/SelectNavigate";
import { AppContext } from "../../variable-Context";

function SelectBigPlaces() {
  const { registeredUser, uidFirebase } = useContext(AppContext);

  const [BigPlaces, setBigPlaces] = useState({
    title: "מתחמים לקבוצות גדולות",
    value: "",
  });

  const listSelectBigPlaces = [
    { title: "קמפוסים", value: "" },
    { title: "וילות גדולות", value: "" },
    { title: "ישיבות", value: "" },
  ];

  return (
    <SelectNavigate
      label={"פרסם מודעה בחינם"}
      link={registeredUser ? `/user/${uidFirebase}` : "/login"}
    />
    // <SelectNavigate
    //   listNavigate={listSelectBigPlaces}
    //   label={"מתחמים לקבוצות גדולות"}
    //   link={"/BigPlaces"}
    //   setNavigate={setBigPlaces}
    // />
  );
}

export default SelectBigPlaces;
