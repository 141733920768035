import React, { useState, useContext } from "react";
import "./landingPage.css";
import Logo from "../../components/Logo/Logo";
import Carousel from "../../components/Carousel/Carousel";
import ScreenHomePage from "./screens/ScreenHomePage/ScreenHomePage";
import ScreenApartmentShow from "./screens/ScreenApartmentShow/ScreenApartmentShow";
import ScreenReplacementSearch from "./screens/ScreenReplacementSearch/ScreenReplacementSearch";
import ScreenPersonalPage from "./screens/ScreenPersonalPage/ScreenPersonalPage";
import ScreenContactUs from "./screens/ScreenContactUs/ScreenContactUs";
import { Button } from "../../components/Input_select_button/Input_select_button";
import { MailOutline } from "@mui/icons-material";
import { AppContext } from "../../variable-Context";
import ButtonMoveLogin from "./ButtonMoveLogin";

const LandingPage = () => {
  const [moveScreen, setMoveScreen] = useState();
  const { innerWidth } = useContext(AppContext);

  const carousel = [
    {
      id: 1,
      content: <ScreenHomePage />,
    },
    {
      id: 2,
      content: <ScreenApartmentShow />,
    },
    {
      id: 3,
      content: <ScreenReplacementSearch />,
    },
    {
      id: 4,
      content: <ScreenPersonalPage />,
    },
    {
      id: 5,
      content: <ScreenContactUs />,
    },
  ];

  return (
    <div className="landingPage " dir="rtl">
      <div className="logo_landingPage">
        <Logo titleColor={innerWidth > 800 ? null : "var(--white)"} />
      </div>
      <div className="div_carousel">
        <Carousel
          content={carousel}
          moveScreen={moveScreen}
          setMoveScreen={setMoveScreen}
        />
      </div>
      <div className="div_footer_landingPage">
        <Button
          title={
            <div>
              כתבו לנו <MailOutline className="icon_landingPage" />
            </div>
          }
          padding={innerWidth > 700 ? "1rem 30px" : "0.5rem 10px"}
          borderRadius="10px"
          onClick={() => {
            setMoveScreen(5);
          }}
        />
        <ButtonMoveLogin />
      </div>
    </div>
  );
};

export default LandingPage;
