const Modal = ({ isOpenModal, setIsOpenModal, content }) => {
  let mql = window.matchMedia("(max-width: 600px)");

  const style = {
    background_modal: {
      width: "100%",
      position: "fixed",
      top: 0,
      right: 0,
      height: "100vh",
      zIndex: 2000,
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      backgroundColor: "#fff",
      borderRadius: "10px",
      padding: "20px",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      fontSize: "1.8rem",
      fontWeight: "bold",
      color: "black",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      zIndex: 1001,
      border: "1px solid #000",
      width: mql.matches ? "80%" : "fit-content",
      minWidth: "300px",
      maxHeight: "85vh",
      maxWidth: "85vw",
      overflow: "auto",
    },
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  return (
    <div
      style={style.background_modal}
      onClick={() => {
        setIsOpenModal(false);
      }}
    >
      <div
        style={style.modal}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="scroll_designed"
      >
        <div style={{ width: "20px", cursor: "pointer" }} onClick={handleClose}>
          X
        </div>
        {content}
      </div>
    </div>
  );
};
export default Modal;
