import "./ViewListApartments.css";
import React, { useEffect, useState, useContext } from "react";
import Apartment from "../apartment/apartment";
import { PostToServer } from "../getData";
import MaintenanceScreen from "../MaintenanceScreen/MaintenanceScreen";
import LoadingSpinning from "../loadingSpinning";
import { shuffle } from "lodash";
import GoogleAds from "../GoogleAds";
import { AppContext } from "../../variable-Context";

function ViewListApartments({
  messageNoApartments,
  route,
  obj,
  responseFavoriteApartments,
}) {
  const [response, setResponse] = useState(responseFavoriteApartments);
  const [error, setError] = useState();
  const [shuffledData, setShuffledData] = useState([]);
  const [data, setData] = useState([]);

  const { innerWidth } = useContext(AppContext);

  useEffect(() => {
    route && PostToServer(route, obj, null, setResponse, setError);
  }, [route, obj]);

  useEffect(() => {
    setResponse(responseFavoriteApartments);
  }, [responseFavoriteApartments]);

  useEffect(() => {
    setShuffledData(shuffle(response?.data));
  }, [response?.data]);

  // מודעות מולטיפלקס
  // useEffect(() => {
  //   for (let i = 4; i < shuffledData.length; i += 5) {
  //     shuffledData.splice(
  //       i,
  //       0,
  //       <GoogleAds
  //         dataAdSlot={"2436942832"}
  //         style={{ display: "block" }}
  //         dataAdFormat="auto"
  //       />
  //     );
  //   }
  //   setData(shuffledData);
  // }, [shuffledData]);

  // מודעה רספונסיבית
  useEffect(() => {
    for (let i = 4; i < shuffledData.length; i += 5) {
      shuffledData.splice(
        i,
        0,
        <div>
          פרסומת
          {innerWidth < 600 ? (
            // <GoogleAds
            //   dataAdSlot={"5244963838"}
            //   style={{
            //     display: "inline-block",
            //     width: "200px",
            //     height: "200px",
            //   }}
            // />
            <GoogleAds
              dataAdSlot={"6176719782"}
              style={{
                display: "inline-block",
                width: "280px",
                height: "280px",
              }}
            />
          ) : (
            <GoogleAds
              dataAdSlot={"6781524324"}
              style={{ display: "block" }}
              dataAdFormat="auto"
            />
          )}
        </div>
      );
    }
    setData(shuffledData);
  }, [shuffledData]);

  return (
    <div className="div_all_apartment">
      {response === undefined ? (
        <LoadingSpinning />
      ) : response?.status === 200 && response?.data?.length === 0 ? (
        <div
          style={{
            width: "fit-content",
            margin: "3rem auto",
            fontSize: "1.5rem",
            textAlign: "center",
          }}
        >
          {messageNoApartments}
        </div>
      ) : shuffledData?.length > 0 ? (
        <Apartment listApartments={data} />
      ) : error ||
        error?.response?.status === 500 ||
        error?.response?.status === 400 ? (
        <MaintenanceScreen
          content={
            <div>
              שגיאת נתונים.
              <br />
              נסו שוב במועד מאוחר יותר.
            </div>
          }
        />
      ) : null}
    </div>
  );
}

export default ViewListApartments;
