import "./ContactUs.css";
import React, { useContext } from "react";
import { AppContext } from "../../variable-Context";
import SendMessage from "../../components/sendMessage/sendMessage";

function ContactUs() {
  const { detailsUsers } = useContext(AppContext);

  return (
    <div className="container_responsive">
      <div className="title_contact_us">כתבו לנו</div>
      <div className="content_contact_us">
        <SendMessage
          data={detailsUsers}
          subject={`פניה להנהלה`}
          url={`${process.env.REACT_APP_SERVER_URL}/api/sendMessage/`}
          category={"management"}
          placeholder={
            "נשמח לשמוע מכם כל הערה, הארה, שיפור וכל העולה על דעתכם ... 😊"
          }
        />
      </div>
    </div>
  );
}

export default ContactUs;
