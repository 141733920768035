import "mapbox-gl/dist/mapbox-gl.css";
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import axios from "axios";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.setRTLTextPlugin(
  "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
  null,
  true // Lazy load the plugin
);

const Maps = ({ streetName, cityName, propslat, propslng }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(propslng);
  const [lat, setLat] = useState(propslat);
  const [zoom, setZoom] = useState(14);

  // useEffect(() => {
  //   axios
  //     .get(
  //       // `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
  //       `https://api.mapbox.com/geocoding/v5/mapbox.places/מירון.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
  //       // `https://api.mapbox.com/geocoding/v5/mapbox.places/${viewport.longitude},${viewport.latitude}.json?access_token=pk.eyJ1IjoiZGV2bGlucm9jaGEiLCJhIjoiY2t2bG82eTk4NXFrcDJvcXBsemZzdnJoYSJ9.aq3RAvhuRww7R_7q-giWpA`
  //     )
  //     .then((res) => {
  //       const { data } = res;
  //       console.log(data.features[0].place_name);
  //       setLng(data.features[0]?.center[0]);
  //       setLat(data.features[0]?.center[1]);
  //     });
  // }, []);

  useEffect(() => {
    setLng(propslng);
    setLat(propslat);
    // console.log("lat", lat);
  }, [propslat, propslng]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    if (!lng || !lat) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
      language: "he",
    });

    new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map.current);
  }, [lng, lat, zoom]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter()?.lng?.toFixed(4));
      setLat(map.current.getCenter()?.lat?.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  return (
    <div
      ref={mapContainer}
      className="map-container"
      style={{ height: "inherit" }}
    />
  );
};

export default Maps;
