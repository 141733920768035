import "./moreApartment.css";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AddToFavorite from "../addToFavorite/addToFavorite";
import Logo from "../Logo/Logo";
import { AppContext } from "../../variable-Context";
import { shuffle } from "lodash";

const MoreApartment = ({ area }) => {
  const [apartment, setApartment] = useState([]);

  const { innerWidth } = useContext(AppContext);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/list/filter/`, {
        area: area,
      })
      .then((res) => {
        setApartment(shuffle(res.data));
      });
  }, [area]);

  return (
    <div className="divMore">
      {" "}
      {apartment.map((apartment) => (
        <div className="boxApartment" key={apartment._id}>
          <div className="addToFavorite">
            <AddToFavorite
              apartmentForFavorite={apartment}
              style={{ width: "10%" }}
            />
          </div>
          <a href={apartment._id} className="link">
            <div>
              {apartment.images.length > 0 ? (
                <img
                  className="imgApartment"
                  src={apartment.images[0]}
                  alt=""
                ></img>
              ) : (
                <div className="imgApartment">
                  <Logo
                    fontSizeText={innerWidth > 600 ? "4rem" : "3.5rem"}
                    fontSizeTitle="0.8rem"
                    sizeImg={innerWidth > 600 ? "3rem" : "2.5rem"}
                  />
                </div>
              )}
            </div>
            <div className="infoApartment">
              <div className="nameApartment">{apartment.nameApartment}</div>
              <div className="location">{apartment.city}</div>
              <div className="beds"> עד {apartment.beds} מיטות</div>
              <div className="price">
                החל מ- {apartment.price} ש"ח ל{apartment.priceMethod}
              </div>
              <div className="phone">{apartment.phone}</div>
            </div>
          </a>
        </div>
      ))}{" "}
    </div>
  );
};

export default MoreApartment;
