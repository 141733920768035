import "./ReplacementSearch.css";
import React, { useContext, useEffect, useState } from "react";
import { PostToServer } from "../../components/getData";
import { SwapHoriz, Room } from "@mui/icons-material/";
import Modal from "../../components/Modal";
import NoticeBoard from "../../components/NoticeBoard/NoticeBoard";
import SearchApartmentShowComponent from "../../components/SearchApartmentShowComponent/SearchApartmentShowComponent";
import SearchBoxForReplacementSearchPage from "../../components/SearchBoxForReplacementSearchPage/SearchBoxForReplacementSearchPage";
import { AppContext } from "../../variable-Context";
import { useHistory } from "react-router-dom";
import AddToFavorite from "../../components/addToFavorite/addToFavorite";
import GoogleAds from "../../components/GoogleAds";
import { shuffle } from "lodash";
import PublishButton from "../../components/PublishButton";

const ReplacementSearch = ({ url }) => {
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState();
  const [shuffledData, setShuffledData] = useState([]);
  const [data, setData] = useState([]);

  const divsSearchAd = [];

  useEffect(() => {
    setShuffledData(shuffle(divsSearchAd));
  }, [divsSearchAd.length, response?.data?.length]);

  useEffect(() => {
    for (let i = 2; i < shuffledData.length; i += 11) {
      shuffledData.splice(
        i,
        0,
        <div className="box_Replacement_Search_google_ads">
          <GoogleAds
            dataAdSlot={"5244963838"}
            style={{
              display: "inline-block",
              width: "200px",
              height: "200px",
            }}
          />
        </div>
      );
    }
    setData(shuffledData);
  }, [shuffledData]);

  const { filterReplacementSearch, setFilterReplacementSearch } =
    useContext(AppContext);

  const history = useHistory();

  useEffect(() => {
    setFilterReplacementSearch("");
  }, [history.location?.pathname]);

  useEffect(() => {
    setResponse();
    PostToServer(url, filterReplacementSearch, null, setResponse, setError);
  }, [filterReplacementSearch]);

  response?.status === 200 && response?.data.length === 0
    ? divsSearchAd.push(
        <div className="box_Replacement_Search_err">
          לא נמצאו מודעות תואמות לנתוני החיפוש שלך.
          <br />
          יש לשנות את נתוני החיפוש ולנסות שוב.
        </div>
      )
    : response?.data?.length > 0
    ? response?.data?.map((item, index) => {
        // if (index % 7 === 0 && index !== 0) {
        //   divsSearchAd.push(
        //     <div key={index} className="box_Replacement_Search_google_ads">
        //       {" "}
        //       <GoogleAds
        //         dataAdSlot={"5244963838"}
        //         style={{
        //           display: "inline-block",
        //           width: "200px",
        //           height: "200px",
        //         }}
        //       />
        //     </div>
        //   );
        // }
        divsSearchAd.push(
          <div key={index} style={{ position: "relative", width: "90%" }}>
            <div
              className="div_AddToFavorite_replacementSearch"
              // onClick={(event) => event.preventDefault()}
            >
              <AddToFavorite
                apartmentForFavorite={item}
                fontSize="2rem !important"
              />
            </div>
            <div
              className="box_Replacement_Search"
              onClick={() => {
                setIsOpenModal(true);
                setContentModal(item);
              }}
            >
              <div className="model_Replacement_Search">
                {item.model === "1"
                  ? "השכרה"
                  : item.model === "2"
                  ? "החלפה"
                  : "השכרה / החלפה"}
              </div>
              <div className="apartment_Search_boxheader">
                מחפשים דירה ב{item.areaSearch}.
              </div>
              <div className="div_all_city_name">
                {item.citySearch.map((item, index) => (
                  <div key={index} className="city_name">
                    <Room style={{ fontSize: "1.7rem", color: "#ad0000" }} />
                    {item}{" "}
                  </div>
                ))}
              </div>
              <div>
                {["2", "3"].includes(item.model) ? (
                  <SwapHoriz style={{ fontSize: "4rem" }} />
                ) : null}
              </div>

              <div className="apartment_Search_boxheader">
                {["2", "3"].includes(item.model) ? (
                  <>
                    החלפה בדירה ב{item.areaChange}
                    <br />
                    <div className="city_name">
                      <Room style={{ fontSize: "1.7rem", color: "#ad0000" }} />{" "}
                      {item.cityChange}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        );
      })
    : error ||
      error?.response?.status === 500 ||
      error?.response?.status === 400
    ? divsSearchAd.push(
        <div className="box_Replacement_Search_err">
          שגיאת נתונים.
          <br />
          נסו שוב במועד מאוחר יותר.
        </div>
      )
    : divsSearchAd.push();

  return (
    <div className="container_responsive">
      <div className="publish_button">
        <PublishButton />
      </div>
      <SearchBoxForReplacementSearchPage />
      <NoticeBoard
        note={data}
        countAdsForPage={15}
        resetPageNumber={filterReplacementSearch}
      />
      {isOpenModal && (
        <Modal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          content={
            <SearchApartmentShowComponent
              data={contentModal}
              sendMessage={true}
            />
          }
        />
      )}
    </div>
  );
};
export default ReplacementSearch;
