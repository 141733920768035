import "./BarNavigation.css";
import SelectAreaHomePage from "./SelectAreaHomePage";
import SelectSearchApartment from "./SelectSearchApartment";
import SelectBigPlaces from "./SelectBigPlaces";
import SearchComponentForHomePage from "../SearchBoxForHomePage/SearchBoxForHomePage";
import SearchComponentForReplacementSearch from "../SearchBoxForReplacementSearchPage/SearchBoxForReplacementSearchPage";
import { AppContext } from "../../variable-Context";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GoogleAds from "../GoogleAds";
import FavoriteInHeader from "../FavoriteInHeader/FavoriteInHeader";

function BarNavigation({ position }) {
  const { innerWidth } = useContext(AppContext);
  const location = useLocation();

  return (
    <div className="bar_navigation" style={{ position: position }}>
      {/* <div className="google_ads_r">
        <GoogleAds
          dataAdSlot={"5167618777"}
          style={{ display: "block" }}
          dataAdFormat={"auto"}
        />
      </div> */}
      <div className="div_navigate">
        {innerWidth < 701 ? (
          location?.pathname === "/" ? (
            <SearchComponentForHomePage />
          ) : location?.pathname === "/searchApartment" ? (
            <SearchComponentForReplacementSearch />
          ) : null
        ) : null}
      </div>

      <div className="div_navigate">
        <SelectAreaHomePage />
      </div>

      <div className="div_navigate">
        <SelectSearchApartment />
      </div>

      {innerWidth > 701 && (
        <div className="div_navigate">
          <SelectBigPlaces />
        </div>
      )}

      <div className="div_navigate">
        {innerWidth < 701 && <FavoriteInHeader />}
      </div>
      {/* <div className="google_ads_l">
        <GoogleAds
          dataAdSlot={"8910357004"}
          style={{ display: "block" }}
          dataAdFormat="auto"
        />
      </div> */}
    </div>
  );
}

export default BarNavigation;
