import "./sendMessage.css";
import { useEffect, useState } from "react";
import { Button } from "../Input_select_button/Input_select_button";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoadingSpinning from "../loadingSpinning";
import { PostToServer } from "../getData";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import ModalTimeOut from "../ModalTimeOut/ModalTimeOut";

function SendMessage({ data, subject, url, category, placeholder }) {
  const [message, setMessage] = useState({});
  const [modal, setModal] = useState(false);
  const [response, setResponse] = useState();

  useEffect(() => {
    modal === false && setResponse();
  }, [modal]);

  const onchange = (e) =>
    setMessage({
      ...message,
      uidFirebase: data?.uidFirebase,
      mailApartment: data?.mail,
      mailUser: data?.mailUser,
      subject: subject,
      category: category,
      idApartment: data?._id,
      [e.target.name]: e.target.value,
    });

  const sendMessage = () => {
    setModal(true);
    PostToServer(url, message, null, setResponse);
    setMessage({});
  };

  const validateEmail = (email) => {
    return Yup.string().email().isValidSync(email);
  };

  const validatePhone = (phone) => {
    return Yup.string()
      .test((phone) => {
        const parsedNumber = !!phone && parsePhoneNumberFromString(phone, "IL");
        return parsedNumber && parsedNumber.isValid() ? true : false;
      })
      .isValidSync(phone);
  };

  const formik = useFormik({
    initialValues: message,
    enableReinitialize: true,
    validationSchema: Yup.object({
      message: Yup.string().min(5, "* מינימום 5 תווים").required("* שדה חובה"),
      phoneMailBack: Yup.string()
        .required("* שדה חובה")
        .test("isValidNumber", "טלפון / מייל אינם תקינים", (value) => {
          return validateEmail(value) || validatePhone(value);
        }),
    }),
    onSubmit: (values) => {
      // console.log(values);
      sendMessage();
    },
  });

  return (
    <div className="send_message">
      {modal ? (
        response === undefined ? (
          <LoadingSpinning />
        ) : (
          <ModalTimeOut
            duration={3}
            setIsOpenModalTimeOut={setModal}
            textMsgModalTimeOut={
              response?.status === 200 ? (
                <div> {"ההודעה נשלחה בהצלחה  \ud83d\ude00"}</div>
              ) : (
                <div>ההודעה לא נשלחה. נסו שוב.</div>
              )
            }
          />
        )
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          style={{ width: "100%", display: "contents" }}
        >
          <input
            type="text"
            id="inputName"
            placeholder="שם"
            name="nameUser"
            onChange={onchange}
            className="input_send_message"
          />
          <div className="error_formik">{formik?.errors.nameUser}</div>

          <input
            type="text"
            id="inputPhone"
            placeholder="טלפון / מייל"
            onChange={onchange}
            name="phoneMailBack"
            className="input_send_message"
          />
          <div className="error_formik">{formik?.errors.phoneMailBack}</div>

          <div
            onInput={(e) => (message.message = e.currentTarget.textContent)}
            id="message"
            contentEditable
            placeholder={
              placeholder ? placeholder : "מפרסמי המודעה ישמחו לשמוע מכם ... 😊"
            }
            className="input_send_message scroll_designed"
          ></div>
          <div className="error_formik">{formik?.errors.message}</div>

          <Button
            title={"שלח"}
            padding={"0.5rem 5.5rem"}
            borderRadius={"10px"}
          />
        </form>
      )}
    </div>
  );
}

export default SendMessage;
