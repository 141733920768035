import "./Add_&_Edit_SearchApartment.css";
import React, { useState, useEffect, useContext } from "react";
import DetailsSearchApartment from "./detailsSearchApartment/detailsSearchApartment";
import DetailsChangeApartment from "./detailsChangeApartment/detailsChangeApartment";
import Contact from "./contact/contact";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AppContext } from "../../variable-Context";
import LoadingSpinning from "../loadingSpinning";
import { PutToServer, PostToServer } from "../getData";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Button } from "../Input_select_button/Input_select_button";
import ModalTimeOut from "../ModalTimeOut/ModalTimeOut";
import Baner from "../baner";
import Calendar from "./calendar";

function SearchApartment({ itemForEdit, setIsOpenModal }) {
  const [response, setResponse] = useState();
  const { detailsUsers, uidFirebase } = useContext(AppContext);
  const [valueCity, setValueCity] = useState(
    itemForEdit ? itemForEdit.cityChange : ""
  );
  const [chooseCity, setChooseCity] = useState(
    itemForEdit?.citySearch ? itemForEdit.citySearch : []
  );
  const [valueCityChange, setValueCityChange] = useState(
    itemForEdit ? itemForEdit.cityChange : ""
  );
  const [chooseCityChange, setChooseCityChange] = useState(
    itemForEdit?.cityChange ? itemForEdit.cityChange : ""
  );
  const [valueStreetChange, setValueStreetChange] = useState(
    itemForEdit ? itemForEdit.streetChange : ""
  );
  const [chooseStreetChange, setChooseStreetChange] = useState(
    itemForEdit?.streetChange ? itemForEdit.streetChange : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [msgmodal, setMsgmodal] = useState(false);
  const [listCityOptions, setListCityOptions] = useState(false);
  const [msgErr, setMsgErr] = useState(false);

  const [apartment, setApartment] = useState({
    uidFirebase: uidFirebase,
    show: 1,
    model: itemForEdit ? itemForEdit.model : "",
    areaSearch: itemForEdit ? itemForEdit.areaSearch : "",
    citySearch: itemForEdit ? itemForEdit.citySearch : [],
    roomsSearch: itemForEdit ? itemForEdit.roomsSearch : "",
    bedsSearch: itemForEdit ? itemForEdit.bedsSearch : "",
    priceSearch: itemForEdit ? itemForEdit.priceSearch : "",
    babyBedSearch: itemForEdit ? itemForEdit.babyBedSearch : "",
    longSearch: itemForEdit ? itemForEdit.longSearch : "",
    areaChange: itemForEdit ? itemForEdit.areaChange : "",
    cityChange: itemForEdit ? itemForEdit.cityChange : "",
    streetChange: itemForEdit ? itemForEdit.streetChange : "",
    roomsChange: itemForEdit ? itemForEdit.roomsChange : "",
    bedsChange: itemForEdit ? itemForEdit.bedsChange : "",
    babyBedChange: itemForEdit ? itemForEdit.babyBedChange : "",
    longChange: itemForEdit ? itemForEdit.longChange : "",
    dateBusy: itemForEdit ? itemForEdit.dateBusy : [],
    name: itemForEdit ? itemForEdit.name : detailsUsers?.nameUser,
    mail: itemForEdit ? itemForEdit.mail : detailsUsers?.mailUser,
    phone: itemForEdit ? itemForEdit.phone : detailsUsers?.phoneUser,
  });

  useEffect(() => {
    setApartment({
      ...apartment,
      name: itemForEdit ? itemForEdit.name : detailsUsers?.nameUser,
      mail: itemForEdit ? itemForEdit.mail : detailsUsers?.mailUser,
      phone: itemForEdit ? itemForEdit.phone : detailsUsers?.phoneUser,
      uidFirebase: uidFirebase,
    });
  }, [detailsUsers, uidFirebase, itemForEdit]);

  useEffect(() => {
    if (response !== undefined) {
      setIsLoading(false);
      setMsgmodal(true);
    }
  }, [response]);

  const formik = useFormik({
    initialValues: apartment,
    enableReinitialize: true,
    validationSchema: Yup.object({
      model: Yup.string().min(1, "חובה לבחור").required("* שדה חובה"),
      areaSearch: Yup.string().min(2, "יש לבחור איזור").required("* שדה חובה"),
      roomsSearch: Yup.number()
        .min(1, "יש לציין מס' חדרים")
        .typeError("יש להקליד רק מספרים")
        .required("* שדה חובה"),
      bedsSearch: Yup.number()
        .min(1, "יש לציין מס' מיטות")
        .typeError("יש להקליד רק מספרים")
        .required("* שדה חובה"),
      babyBedSearch: Yup.string().min(1, "יש לבחור ").required("* שדה חובה"),
      areaChange:
        apartment.model === "2" || apartment.model === "3"
          ? Yup.string().min(2, "יש לבחור איזור").required("* שדה חובה")
          : null,
      cityChange:
        apartment.model === "2" || apartment.model === "3"
          ? valueCityChange?.length < 1
            ? Yup.string().min(1, "יש לבחור עיר").required("* שדה חובה")
            : Yup.string()
                .min(1, "יש לבחור עיר")
                .required("* יש לבחור עיר מתוך הרשימה")
          : null,
      streetChange:
        apartment.model === "2" || apartment.model === "3"
          ? valueStreetChange?.length < 1
            ? Yup.string().min(1, "יש לבחור רחוב").required("* שדה חובה")
            : valueStreetChange?.length > 0 && !chooseCityChange
            ? Yup.string().min(1, "יש לבחור רחוב").required("* יש לבחור עיר")
            : Yup.string()
                .min(1, "יש לבחור רחוב")
                .required("* יש לבחור רחוב מתוך הרשימה")
          : null,

      bedsChange:
        apartment.model === "2" || apartment.model === "3"
          ? Yup.number()
              .min(1, "יש לציין מס' מיטות")
              .typeError("יש להקליד רק מספרים")
              .required("* שדה חובה")
          : null,

      roomsChange:
        apartment.model === "2" || apartment.model === "3"
          ? Yup.number()
              .min(1, "יש לציין מס' חדרים")
              .typeError("יש להקליד רק מספרים")
              .required("* שדה חובה")
          : null,
      babyBedChange:
        apartment.model === "2" || apartment.model === "3"
          ? Yup.string().min(1, "יש לבחור ").required("* שדה חובה")
          : null,
      dateBusy: Yup.array()
        .min(1, "יש לציין את הזמנים המבוקשים")
        .required("* שדה חובה"),

      name: Yup.string().min(2, "מינימום 2 תווים").required("* שדה חובה"),
      mail: Yup.string().email("לא תקין").required("* שדה חובה"),
      phone: Yup.string()
        .required("* שדה חובה")
        .test("isValidNumber", "מס' אינו תקין", (phone) => {
          const parsedNumber =
            !!phone && parsePhoneNumberFromString(phone, "IL");
          return parsedNumber && parsedNumber.isValid() ? true : false;
        }),
    }),
    onSubmit: (values) => {
      // console.log(values);
      addApartment();
    },
  });

  const scrollToError = () => {
    const errorFields = Object.keys(formik.errors); // משיג את השדות עם שגיאות
    if (errorFields.length > 0) {
      setMsgErr(true);
      const errorField = errorFields[0]; // משתמש בשדה הראשון כדי לגלול אליו
      const errorElement = document.querySelector(`[name=${errorField}]`);
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } else {
      setMsgErr(false);
    }
  };

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      scrollToError();
    }
  }, [formik]);

  const addApartment = async () => {
    setIsLoading(true);
    itemForEdit?._id
      ? PutToServer(
          `${process.env.REACT_APP_SERVER_URL}/api/editSearchApartment/${itemForEdit._id}`,
          {
            ...apartment,
          },
          setResponse
        )
      : PostToServer(
          `${process.env.REACT_APP_SERVER_URL}/api/addSearchApartment`,
          {
            ...apartment,
          },
          null,
          setResponse
        );
  };

  const onchange = (e) => {
    setApartment({
      ...apartment,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setApartment({
      ...apartment,
      citySearch: chooseCity,
    });
  }, [chooseCity]);

  return (
    <div
      style={{ width: "100%" }}
      onClick={() => {
        if (listCityOptions === true) setListCityOptions(false);
      }}
    >
      {!isLoading && !msgmodal ? (
        <div className="addApartment">
          <form onSubmit={formik.handleSubmit}>
            <div className="tabs">
              <Baner content={"פרטי הדירה שאתם מחפשים"} />
              <div className="content">
                <DetailsSearchApartment
                  setChooseCity={setChooseCity}
                  chooseCity={chooseCity}
                  formik={formik}
                  onchange={onchange}
                  itemForEdit={itemForEdit}
                  listCityOptions={listCityOptions}
                  setListCityOptions={setListCityOptions}
                  apartment={apartment}
                  setApartment={setApartment}
                />{" "}
              </div>
              {apartment.model === "2" || apartment.model === "3" ? (
                <div>
                  <Baner content={"פרטי הדירה שלכם"} />
                  <div className="content">
                    <DetailsChangeApartment
                      valueCityChange={valueCityChange}
                      setValueCityChange={setValueCityChange}
                      chooseCityChange={chooseCityChange}
                      setChooseCityChange={setChooseCityChange}
                      valueStreetChange={valueStreetChange}
                      setValueStreetChange={setValueStreetChange}
                      chooseStreetChange={chooseStreetChange}
                      setChooseStreetChange={setChooseStreetChange}
                      formik={formik}
                      onchange={onchange}
                      itemForEdit={itemForEdit}
                      apartment={apartment}
                      setApartment={setApartment}
                    />{" "}
                  </div>
                </div>
              ) : null}
              <Baner content={"תאריכים מבוקשים"} />
              <div className="content">
                <Calendar
                  apartment={apartment}
                  setApartment={setApartment}
                  itemForEdit={itemForEdit}
                  formik={formik}
                  name={"dateBusy"}
                />{" "}
              </div>{" "}
              <Baner content={"פרטי יצירת קשר"} />
              <div className="content">
                <Contact
                  apartment={apartment}
                  setApartment={setApartment}
                  formik={formik}
                  onchange={onchange}
                  itemForEdit={itemForEdit}
                />{" "}
              </div>
            </div>

            <div className="div_btns">
              <div>
                <Button
                  title={itemForEdit ? "שמור שינויים" : "פרסם דירה"}
                  padding={"0.5rem 1.5rem"}
                  borderRadius={"10px"}
                  type={"submit"}
                />
                {msgErr && (
                  <div className="msgErr">
                    * יש לתקן את השגיאות במילוי הטופס
                  </div>
                )}
              </div>
              {itemForEdit ? (
                <Button
                  title={"בטל שינויים"}
                  padding={"0.5rem 1.5rem"}
                  borderRadius={"10px"}
                  onClick={() => setIsOpenModal(false)}
                />
              ) : null}
            </div>
          </form>
        </div>
      ) : isLoading ? (
        <LoadingSpinning />
      ) : msgmodal ? (
        <ModalTimeOut
          duration={10}
          setIsOpenModalTimeOut={itemForEdit ? setIsOpenModal : setMsgmodal}
          textMsgModalTimeOut={
            response?.status === 200 ? (
              itemForEdit ? (
                <div>פרטי המודעה עודכנו בהצלחה.</div>
              ) : (
                <div>
                  פרטי המודעה נשלחו בהצלחה.
                  <br />
                  {/* <br /> בדקות הקרובות צוות האתר יבחן את המודעה
                  <br />
                  ובאם המודעה תמצא מתאימה היא תפורסם באתר. */}
                  <br />
                  <br /> אנו שמחים שהצטרפתם אלינו ומקווים כי תפיקו תועלת מהאתר.
                </div>
              )
            ) : (
              <div>
                עקב שגיאה המודעה לא עודכנה
                <br />
                <br />
                ניתן לפנות אלינו בטופס צור קשר{" "}
              </div>
            )
          }
        />
      ) : null}
    </div>
  );
}

export default SearchApartment;
