import React, { useState, useContext } from "react";
import { Button } from "./Input_select_button/Input_select_button";
import { AppContext } from "../variable-Context";

const TabsButton = ({ content }) => {
  const [post, setPost] = useState(1);
  const { innerWidth } = useContext(AppContext);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex" }}>
        {content?.map((item, index) => (
          <div
            key={index}
            style={{
              opacity: post === item.id ? 1 : 0.5,
              marginInline: "20px",
            }}
          >
            <Button
              title={item.title}
              padding={innerWidth < 600 ? "0.5rem" : "0.5rem 1.5rem"}
              borderRadius={"10px"}
              onClick={() => {
                setPost(item.id);
              }}
            />
          </div>
        ))}
      </div>

      {content?.map((item, index) => (
        <div
          key={index}
          style={{ display: post === item.id ? "block" : "none" }}
        >
          {item.component}
        </div>
      ))}
    </div>
  );
};
export default TabsButton;
