import "./ScreenApartmentShow.css";
import imgApartmentShow from "./ApartmentShow.png";

import {
  LibraryAddCheck,
  CameraAlt,
  LocationOn,
  CalendarMonth,
  Sell,
  Email,
} from "@mui/icons-material";

const ScreenApartmentShow = () => {
  return (
    <div className="screen_apartment_show scroll_designed">
      <div className="img_screen_apartment_show">
        <img src={imgApartmentShow} alt="postApartment" />
      </div>
      <div className="text_screen_apartment_show">
        <div>באתר החדש "וינפש" תוכלו לפרסם את מקום האירוח שלכם ולהציג:</div>
        <div className="icon_special">
          <div className="icon_screen_apartmentShow">
            <CameraAlt style={{ fontSize: "2rem" }} />
          </div>
          תמונות שיכולות לשכנע את המבקרים על יופיו של המקום.
        </div>
        <div className="icon_special">
          <div className="icon_screen_apartmentShow">
            <LibraryAddCheck style={{ fontSize: "2rem" }} />{" "}
          </div>
          תיאור מפורט שיעזור למבקרים להבין את הייחודיות של האירוח שלכם.
        </div>
        <div className="icon_special">
          <div className="icon_screen_apartmentShow">
            <CalendarMonth style={{ fontSize: "2rem" }} />{" "}
          </div>
          תאריכים פנויים בלוח עברי/לועזי, כך שהמבקרים יוכלו לתכנן את החופשה שלהם
          בהתאם.
        </div>
        <div className="icon_special">
          <div className="icon_screen_apartmentShow">
            <Sell style={{ fontSize: "2rem" }} />{" "}
          </div>
          מחירים משתלמים ושקופים, כדי למשוך את המבקרים הרציניים.
        </div>
        <div className="icon_special">
          <div className="icon_screen_apartmentShow">
            <Email style={{ fontSize: "2rem" }} />{" "}
          </div>
          שליחת הודעות ישירות למייל האישי שלכם, כדי לתת למבקרים אפשרות ליצור קשר
          איתכם בקלות ובמהירות.
        </div>
        <div className="icon_special">
          <div className="icon_screen_apartmentShow">
            <LocationOn style={{ fontSize: "2rem" }} />{" "}
          </div>
          מפה עם מיקום האירוח כדי להקל על המבקרים בתכנון השהייה שלהם.
        </div>
        <div>
          אל תחמיצו את ההזדמנות הזו להגיע לקהל המתאים ולפרסם את מקום האירוח
          שלכם. הצטרפו עכשיו לאתר "וינפש" ותמצאו דרך פשוטה ויעילה למשוך תשומת לב
          של מבקרים שמחפשים חופשה בדיוק אצלכם.
        </div>
      </div>
    </div>
  );
};

export default ScreenApartmentShow;
