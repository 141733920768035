import React, { createContext, useState } from "react";

export const AppContext = createContext(null);

export const ContextProvider = ({ children }) => {
  const [uidFirebase, setUidFirebase] = useState("");
  const [detailsUsers, setDetailsUsers] = useState([]);
  const [filterApartments, setFilterApartments] = useState();
  const [listIDForFavorite, setListIDForFavorite] = useState([]);
  const [responseFavoriteApartments, setResponseFavoriteApartments] = useState(
    []
  );
  const [
    responseFavoriteReplacementSearch,
    setResponseFavoriteReplacementSearch,
  ] = useState([]);
  const [registeredUser, setRegisteredUser] = useState();
  const [isManager, setIsManager] = useState(false);
  const [innerWidth, setinnerWidth] = useState(0);
  const [filterReplacementSearch, setFilterReplacementSearch] = useState();

  const globalVariable = {
    filterApartments: filterApartments,
    setFilterApartments: (value) => setFilterApartments(value),
    filterReplacementSearch: filterReplacementSearch,
    setFilterReplacementSearch: (value) => setFilterReplacementSearch(value),
    responseFavoriteApartments: responseFavoriteApartments,
    setResponseFavoriteApartments: (value) =>
      setResponseFavoriteApartments(value),
    responseFavoriteReplacementSearch: responseFavoriteReplacementSearch,
    setResponseFavoriteReplacementSearch: (value) =>
      setResponseFavoriteReplacementSearch(value),
    // listApartments: listApartments,
    // setListApartments: (value) => setListApartments(value),
    listIDForFavorite: listIDForFavorite,
    setListIDForFavorite: (value) => setListIDForFavorite(value),
    isManager: isManager,
    setIsManager: (value) => setIsManager(value),
    registeredUser: registeredUser,
    setRegisteredUser: (value) => setRegisteredUser(value),
    detailsUsers: detailsUsers[0],
    setDetailsUsers: (value) => setDetailsUsers(value),
    uidFirebase: uidFirebase,
    setUidFirebase: (value) => setUidFirebase(value),
    innerWidth: innerWidth,
    setinnerWidth: (value) => setinnerWidth(value),
  };
  return (
    <AppContext.Provider value={globalVariable}>{children}</AppContext.Provider>
  );
};
