import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

function SliderImage({
  listImages,
  isAutoPlaying,
  hasSizeButton,
  hasThumbnails,
}) {
  return (
    <div dir="ltr" style={{ height: "100%", width: "100%" }}>
      <Carousel
        isLoop={true}
        isAutoPlaying={isAutoPlaying}
        autoPlayInterval={10000}
        transitionDurationMin={1000}
        // hasIndexBoard={false}
        hasMediaButton={false}
        hasSizeButton={hasSizeButton}
        hasThumbnails={hasThumbnails}
        images={listImages?.images?.map((image) => ({ src: image }))}
        style={{ height: "100%", width: "100%" }}
        // objectFit="contain"
      />
    </div>
  );
}

export default SliderImage;
