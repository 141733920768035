import "./addToFavorite.css";
import React, { useState, useEffect, useContext } from "react";
import { FavoriteBorder, Favorite } from "@mui/icons-material/";
import { AppContext } from "../../variable-Context";

function AddToFavorite({ apartmentForFavorite, fontSize }) {
  const { listIDForFavorite, setListIDForFavorite, detailsUsers } =
    useContext(AppContext);

  const [iconFavorite, setIconFavorite] = useState("");
  const [msgErr, setMsgErr] = useState(false);

  const favorite = (
    <Favorite
      className="favoriteIcon"
      sx={{
        fontSize: fontSize ? fontSize : "3rem !important",
      }}
    />
  );
  const notFavorite = (
    <FavoriteBorder
      className="favoriteIcon"
      sx={{
        fontSize: fontSize ? fontSize : "3rem !important",
      }}
    />
  );

  const findFavorite = listIDForFavorite?.find(
    (favorite) => favorite === apartmentForFavorite?._id
  );

  useEffect(() => {
    setIconFavorite(findFavorite ? favorite : notFavorite);
  }, [listIDForFavorite]);

  const changeFavorite = async () => {
    if (detailsUsers) {
      if (!findFavorite) {
        setListIDForFavorite([...listIDForFavorite, apartmentForFavorite?._id]);
      } else {
        const removeFavorite = listIDForFavorite.filter(
          (favorite) => apartmentForFavorite?._id !== favorite
        );
        setListIDForFavorite(removeFavorite);
      }
    } else setMsgErr(!msgErr);
  };

  return (
    <div className="add_to_favorite" onClick={changeFavorite}>
      {iconFavorite}
      {msgErr && (
        <div className="msg_err_add_to_favorite">
          <div
            className="cancel_msg_err"
            onClick={() => {
              setMsgErr(!msgErr);
            }}
          >
            X
          </div>
          על מנת להוסיף מודעה למועדפים יש להתחבר לאתר
          <br />
          <a href="/login/" style={{ textDecoration: "underline" }}>
            התחברות{" "}
          </a>
        </div>
      )}
    </div>
  );
}

export default AddToFavorite;
