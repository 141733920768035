import React, { useState, useContext } from "react";
import { Button } from "./Input_select_button/Input_select_button";
import { AppContext } from "../variable-Context";
import { Link } from "react-router-dom";

const PublishButton = () => {
  const { registeredUser, uidFirebase, innerWidth } = useContext(AppContext);

  return (
    <div style={{ display: "flex" }}>
      <Link to={registeredUser ? `/user/${uidFirebase}` : "/login"}>
        <Button
          title="פרסם מודעה בחינם"
          padding={innerWidth < 600 ? "0.5rem" : "0.5rem 1.5rem"}
          borderRadius={"50px"}
        />
      </Link>
    </div>
  );
};
export default PublishButton;
