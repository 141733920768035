import "./SearchApartmentShowComponent.css";
import Info from "./info";
import Header from "./header/header";

function SearchApartmentShowComponent({ data, sendMessage }) {
  return (
    <div className="SearchApartmentShow">
      <Header data={data} />

      <Info data={data} sendMessage={sendMessage} />
    </div>
  );
}

export default SearchApartmentShowComponent;
