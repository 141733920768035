import "./SearchBoxForReplacementSearchPage.css";
import React, { useState, useEffect, useContext } from "react";
import {
  Select,
  SelectCheckbox,
  TextArea,
} from "../Input_select_button/Input_select_button";
import { Search } from "@mui/icons-material";
import Modal from "../Modal";
import { AppContext } from "../../variable-Context";
import { SwapHoriz } from "@mui/icons-material/";
import { listCitiesByArea } from "../ListCitiesByArea";
import { PostToServer } from "../getData";

function SearchForReplacementSearch() {
  const { innerWidth, setFilterReplacementSearch } = useContext(AppContext);

  const [searchApartments, setSearchApartments] = useState();
  const [selectCity, setSelectCity] = useState([]);
  const [selectCityChange, setSelectCityChange] = useState([]);
  const [listCityChange, setListCityChange] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [listCityForSearch, setListCityForSearch] = useState();

  useEffect(() => {
    PostToServer(
      `${process.env.REACT_APP_SERVER_URL}/api/listPostSearch`,
      null,
      setListCityForSearch
    );
  }, []);

  const listCitiesForSplice = [];

  const onchange = (e) => {
    setSearchApartments({
      ...searchApartments,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    listCitiesByArea(
      listCitiesForSplice,
      "citySearch",
      searchApartments,
      "areaSearch",
      setListCity
    );
    listCitiesByArea(
      listCityForSearch,
      "cityChange",
      searchApartments,
      "areaChange",
      setListCityChange
    );
  }, [searchApartments, listCityForSearch]);

  for (let index = 0; index < listCityForSearch?.length; index++) {
    if (
      listCityForSearch[index]?.citySearch.length > 0 &&
      listCityForSearch[index]?.citySearch !== undefined &&
      listCityForSearch[index]?.citySearch !== null &&
      listCityForSearch[index]?.citySearch !== ""
    ) {
      for (let a = 0; a < listCityForSearch[index]?.citySearch?.length; a++) {
        listCitiesForSplice.push({
          citySearch: listCityForSearch[index]?.citySearch[a],
          areaSearch: listCityForSearch[index]?.areaSearch,
        });
      }
    }
  }

  const modelReplacementSearch = [
    { title: `השכרה`, value: "1" },
    { title: "החלפה", value: "2" },
    // { title: "השכרה / החלפה", value: "3" },
  ];

  const area = [
    { title: "בכל הארץ", value: "" },
    { title: "איזור הצפון", value: "איזור הצפון" },
    { title: "איזור המרכז", value: "איזור המרכז" },
    { title: "איזור הדרום", value: "איזור הדרום" },
    { title: "איזור ירושלים", value: "איזור ירושלים" },
  ];

  const componentSearch = (
    <div className="Search_Component">
      <div className="Inputs_Search">
        <Select
          name={"model"}
          label={"השכרה / החלפה"}
          onChange={onchange}
          options={modelReplacementSearch}
          width={innerWidth > 700 ? "17%" : "80%"}
          defaultValue={searchApartments?.model}
        />
        <Select
          name={"areaSearch"}
          label={"בחר איזור בארץ"}
          onChange={(e) => {
            setSelectCity([]);
            setSearchApartments({
              ...searchApartments,
              [e.target.name]: e.target.value,
              city: "",
            });
          }}
          options={area}
          width={innerWidth > 700 ? "17%" : "80%"}
        />
        <SelectCheckbox
          name={"citySearch"}
          label={"בחר עיר"}
          options={listCity}
          width={innerWidth > 700 ? "17%" : "80%"}
          defaultValue={selectCity}
          checked={selectCity}
          setChecked={setSelectCity}
          state={searchApartments}
          setState={setSearchApartments}
        />
        {/* {searchApartments?.model === "2" || searchApartments?.model === "3" ? (
          <> */}
        <SwapHoriz style={{ fontSize: "3rem" }} />
        <Select
          name={"areaChange"}
          label={"בחר איזור בארץ"}
          onChange={(e) => {
            setSelectCityChange([]);
            setSearchApartments({
              ...searchApartments,
              [e.target.name]: e.target.value,
              cityChange: "",
            });
          }}
          options={area}
          width={innerWidth > 700 ? "17%" : "80%"}
        />
        <SelectCheckbox
          name={"cityChange"}
          label={"בחר עיר"}
          options={listCityChange}
          width={innerWidth > 700 ? "17%" : "80%"}
          defaultValue={selectCityChange}
          checked={selectCityChange}
          setChecked={setSelectCityChange}
          state={searchApartments}
          setState={setSearchApartments}
        />{" "}
        {/* </>
        ) : (
          <TextArea
            label={"כמות מיטות"}
            width={innerWidth > 700 ? "17%" : "80%"}
            height={"2.4rem"}
            content={
              <div className="div_input_beds">
                מ-
                <input
                  className="input_beds"
                  onChange={(e) => {
                    setSearchApartments({
                      ...searchApartments,
                      minBeds: e.target.value,
                    });
                  }}
                  defaultValue={searchApartments?.minBeds}
                />{" "}
                עד-
                <input
                  className="input_beds"
                  onChange={(e) => {
                    setSearchApartments({
                      ...searchApartments,
                      maxBeds: e.target.value,
                    });
                  }}
                  defaultValue={searchApartments?.maxBeds}
                />
              </div>
            }
            contentEditable={false}
          />
        )} */}
        <div>
          <Search
            style={{
              fontSize: "5rem",
              color: "var(--blue)",
              cursor: "pointer",
            }}
            onClick={() => {
              setFilterReplacementSearch(searchApartments);
              setIsOpenModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );

  return innerWidth > 701 ? (
    componentSearch
  ) : (
    <div className="div_icon_search_mobile">
      <Search
        style={{
          fontSize: "4rem",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => {
          setListCity([]);
          setListCityChange([]);
          setSearchApartments({});
          setSelectCity([]);
          setSelectCityChange([]);
          setIsOpenModal(true);
        }}
      />

      {isOpenModal && (
        <Modal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          content={componentSearch}
        />
      )}
    </div>
  );
}

export default SearchForReplacementSearch;
