import React, { useState, useContext } from "react";
import Modal from "../Modal";
import { PutToServer, PostToServer } from "../getData";
import { AppContext } from "../../variable-Context";
import { Button } from "../Input_select_button/Input_select_button";
import { useEffect } from "react";

const HoldApartment = ({
  render,
  setRender,
  setIsOpenModal,
  setContentModal,
  item,
}) => {
  const { isManager } = useContext(AppContext);
  let mql = window.matchMedia("(max-width: 600px)");

  const sendMessageSuccess = {
    nameUser: "הנהלת האתר",
    mailApartment: item.mail,
    content: `
    <div style="text-align: right;
    font-size: 14px;
    font-weight: 600;
    direction: rtl;
    padding: 1rem;">
   <h3>היי, ${item.name}</h3>

    <p>אנו שמחים לעדכן אותך כי המודעה שלך אושרה ומופיעה באתר.</p>

    <a href="${process.env.REACT_APP_WEB_URL}/apartmentShow/${item._id}">לחץ כאן לצפייה במודעה</a>

    <p>באפשרותך לגשת <a href="https://www.vainafash.co.il/login/">לאיזור האישי</a> כדי לעדכן את המודעה, להעלות תמונות נוספות ולערוך את התוכן בכל עת.</p>

    <p>אנחנו מקווים שתהנו משימושכם באתר "וינפש".</p>

    <p>נשמח לשמוע תגובות והערות כדי לשפר את האתר שלנו.</p>
    </div>
    `,
  };

  const holdApartment = () => {
    PutToServer(
      `${process.env.REACT_APP_SERVER_URL}/api/holdApartment/${item._id}`,
      item.show === "1" ? { show: "2" } : { show: "1" }
    );

    item.show === "0" &&
      PostToServer(
        `${process.env.REACT_APP_SERVER_URL}/api/sendMessage/`,
        sendMessageSuccess
      );

    setIsOpenModal(false);
    setRender(!render);
  };

  const OpenModal = () => {
    setIsOpenModal(true);
    setContentModal(
      <div>
        {item.show === "0"
          ? "מאשר את המודעה?"
          : "האם אתה בטוח שברצונך להקפיא את המודעה?"}
        <div className="btnsModal">
          <Button
            title={item.show === "0" ? "אישור" : "הקפא מודעה"}
            onClick={holdApartment}
            padding={"0.3rem 1rem"}
            borderRadius={"10px"}
          />
          <Button
            title={"ביטול"}
            onClick={() => {
              setIsOpenModal(false);
            }}
            padding={"0.3rem 1rem"}
            borderRadius={"10px"}
          />
        </div>
      </div>
    );
  };

  return (
    <div style={{ margin: "0 auto" }}>
      <Button
        title={
          item.show === "0"
            ? "ממתין לאישור"
            : item.show === "1"
            ? "הקפא מודעה"
            : "הפעל מודעה"
        }
        disabled={isManager ? false : item.show === "0" ? true : false}
        onClick={() => {
          item.show === "1" || item.show === "0"
            ? OpenModal()
            : holdApartment();
        }}
        padding={mql.matches ? "0.5rem" : "0.5rem 5.5rem"}
        borderRadius={"10px"}
      />
    </div>
  );
};
export default HoldApartment;
