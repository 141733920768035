import React, { useEffect } from "react";

const GoogleAds = ({ dataAdSlot, style, dataAdFormat, datadLayout }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={style}
      data-ad-client={process.env.REACT_APP_GOOGLE_AD_CLIENT}
      data-ad-slot={dataAdSlot}
      data-ad-format={dataAdFormat}
      data-full-width-responsive="true"
      // data-ad-layout={datadLayout}
      // data-ad-layout-key={"-73+ed+2i-1n-4w"}
    ></ins>
  );
};

export default GoogleAds;
