import React, { useState } from "react";
import "./register.css";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function Register({ setConnectUserForFirebase }) {
  const [typePassword, setTypePassword] = useState("password");
  const [typePasswordAgain, setTypePasswordAgain] = useState("password");
  const [register, setRegister] = useState("");

  const auth = getAuth();

  const showPassword = (typePassword, setTypePassword) => {
    typePassword === "password"
      ? setTypePassword("text")
      : setTypePassword("password");
  };

  const onChange = (e) => {
    setRegister({
      ...register,
      [e.target.name]: e.target.value,
    });
  };

  const iconTypePassword = (typePassword, setTypePassword) => {
    return (
      <div
        className="iconhiden"
        onClick={() => {
          showPassword(typePassword, setTypePassword);
        }}
      >
        {typePassword === "password" ? (
          <Visibility
            style={{
              fontSize: "2.5rem",
              color: "black",
            }}
          />
        ) : (
          <VisibilityOff
            style={{
              fontSize: "2.5rem",
              color: "black",
            }}
          />
        )}
      </div>
    );
  };

  const formik = useFormik({
    initialValues: register,
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .required("* שדה חובה")
        .test("isValid", "כתובה מייל אינה תקינה", (value) => {
          return Yup.string().email().isValidSync(value);
        }),
      password: Yup.string()
        .required("* שדה חובה")
        .min(6, "סיסמא מינימום 6 תווים"),
      passwordAgain: Yup.string()
        .required("* שדה חובה")
        .min(6, "סיסמא מינימום 6 תווים")
        .oneOf([Yup.ref("password"), null], "הסיסמאות אינן תואמות"),
    }),
    onSubmit: (values, { setErrors }) => {
      createUserWithEmailAndPassword(auth, register?.email, register?.password)
        .then(() => {
          setConnectUserForFirebase(true);
        })
        .catch((error) => {
          const errorCode = error.code;
          if (errorCode === "auth/weak-password") {
            setErrors({ password: "סיסמא חלשה" });
          } else if (errorCode === "auth/invalid-email") {
            setErrors({ email: "כתובת מייל לא תקינה" });
          } else if (errorCode === "auth/email-already-in-use") {
            setErrors({ email: "כתובת מייל זו כבר רשומה באתר" });
          } else {
            setErrors({ passwordAgain: "רישום משתמש נכשל. אנא נסה שוב" });
          }
        });
    },
  });

  return (
    <div className="register">
      <div>הרשמה</div>
      <input type="text" name="email" className="noneDisplay" />
      <input type="password" className="noneDisplay" />

      <form
        onSubmit={formik.handleSubmit}
        style={{ width: "100%", display: "contents" }}
      >
        <input
          placeholder="אימייל"
          type="text"
          name="email"
          onChange={onChange}
        />
        <div className="divErr">{formik?.errors.email} </div>

        <div className="diviconhiden">
          <input
            placeholder="סיסמא"
            type={typePassword}
            name="password"
            onChange={onChange}
          />
          {iconTypePassword(typePassword, setTypePassword)}
        </div>
        <div className="divErr">{formik?.errors.password}</div>

        <div className="diviconhiden">
          <input
            placeholder="אשר סיסמא"
            type={typePasswordAgain}
            name="passwordAgain"
            onChange={onChange}
          />
          {iconTypePassword(typePasswordAgain, setTypePasswordAgain)}
        </div>
        <div className="divErr">{formik?.errors.passwordAgain}</div>

        <button>הרשם</button>
      </form>
    </div>
  );
}
