import "./header.css";
import { Call } from "@mui/icons-material";

function Info({ apartmentShow }) {
  return (
    <div className="header header_mobile">
      {/* <div className="header_mobile"> */}
      <div className="nameHeader">
        {apartmentShow?.nameApartment}
        <span className="addressHeader">ב{apartmentShow?.city}.</span>
        {/* </div> */}
      </div>

      <div className="phoneHeader">
        {apartmentShow?.name} - {apartmentShow?.phone}
      </div>
    </div>
  );
}

export default Info;
