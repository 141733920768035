import React, { useState } from "react";
import "./ConnectWithUser.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

export default function ConnectWithUser({ setConnectUserForFirebase }) {
  const [typePassword, setTypePassword] = useState("password");
  const [connectAndReset, setConnectAndReset] = useState(true);
  const [user, setUseer] = useState();

  const auth = getAuth();

  const showPassword = (typePassword, setTypePassword) => {
    typePassword === "password"
      ? setTypePassword("text")
      : setTypePassword("password");
  };

  const onChange = (e) => {
    setUseer({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const iconTypePassword = (typePassword, setTypePassword) => {
    return (
      <div
        className="iconhiden"
        onClick={() => {
          showPassword(typePassword, setTypePassword);
        }}
      >
        {typePassword === "password" ? (
          <Visibility
            style={{
              fontSize: "2.5rem",
              color: "black",
            }}
          />
        ) : (
          <VisibilityOff
            style={{
              fontSize: "2.5rem",
              color: "black",
            }}
          />
        )}
      </div>
    );
  };

  const errorFirebase = (error, setErrors) => {
    const errorCode = error.code;
    if (errorCode === "auth/wrong-password") {
      setErrors({ password: "סיסמא שגויה" });
    } else if (errorCode === "auth/invalid-email") {
      setErrors({ email: "כתובת מייל לא תקינה" });
    } else if (errorCode === "auth/user-not-found") {
      setErrors({ email: "משתמש אינו קיים" });
    } else if (errorCode === "auth/user-disabled") {
      setErrors({ email: "החשבון מושבת" });
    } else {
      setErrors({ password: "כניסת משתמש נכשלה. אנא נסה שוב" });
    }
  };

  const formik = useFormik({
    initialValues: user,
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .required("* שדה חובה")
        .test("isValid", "כתובה מייל אינה תקינה", (value) => {
          return Yup.string().email().isValidSync(value);
        }),
      password: Yup.string()
        .required("* שדה חובה")
        .min(6, "סיסמא מינימום 6 תווים"),
    }),
    onSubmit: (values, { setErrors }) => {
      connectAndReset === true
        ? signInWithEmailAndPassword(auth, user?.email, user?.password)
            .then(() => {
              setConnectUserForFirebase(true);
              setErrors({ password: "התחברת בהצלחה" });
            })
            .catch((error) => {
              errorFirebase(error, setErrors);
            })
        : sendPasswordResetEmail(auth, user?.email)
            .then(() => {
              setErrors({ email: "נשלח מייל לאיפוס הסיסמא" });
              setTimeout(function () {
                setConnectAndReset(true);
              }, 4000);
            })
            .catch((error) => {
              errorFirebase(error, setErrors);
            });
    },
  });

  return (
    <div className="ConnectWithUser">
      <form
        onSubmit={formik.handleSubmit}
        style={{ width: "100%", display: "contents" }}
      >
        {connectAndReset === true ? (
          <div>
            <div>רשומים כבר?</div>
            <input
              placeholder="אימייל"
              type="text"
              name="email"
              onChange={onChange}
            />
            <div className="divErr">{formik?.errors.email} </div>

            <div className="diviconhiden">
              <input
                placeholder="סיסמא"
                type={typePassword}
                name="password"
                onChange={onChange}
              ></input>
              {iconTypePassword(typePassword, setTypePassword)}
            </div>
            <div className="divErr">{formik?.errors.password}</div>

            <button>אישור</button>

            <div className="divReset">
              שכחת סיסמא?{" "}
              <span
                className="underline"
                onClick={() => {
                  setConnectAndReset(false);
                }}
              >
                לחץ כאן
              </span>
            </div>
          </div>
        ) : (
          <div>
            <div>איפוס סיסמא</div>
            <input
              placeholder="הכנס את כתובת המייל שאיתה נרשמת"
              type="text"
              name="email"
              onChange={onChange}
            />

            <div className="divErr">{formik?.errors.email} </div>

            <button>אפס סיסמא</button>
            <br />
            <br />
            <button
              onClick={() => {
                setConnectAndReset(true);
              }}
            >
              ביטול{" "}
            </button>
          </div>
        )}{" "}
      </form>
    </div>
  );
}
