import "./ScreenContactUs.css";
import React, { useContext } from "react";
import { AppContext } from "../../../../variable-Context";
import SendMessage from "../../../../components/sendMessage/sendMessage";
import Logo from "../../../../components/Logo/Logo";

const ScreenContactUs = () => {
  const { detailsUsers } = useContext(AppContext);

  return (
    <div className="screen_contactUs scroll_designed">
      <div className="div_contactUs">
        <div className="screen_contactUs_r">
          אז למה אתם מחכים?
          <br /> כנסו עוד היום, הרשמו לאתר שלנו ותפרסמו את המודעה שלכם! <br />
          <div
            className="logo_contactUs"
            onClick={() => {
              window.open("https://www.vainafash.co.il/login/", "_blank");
            }}
          >
            <Logo />
          </div>
        </div>
        <div className="screen_contactUs_footer">
          אנחנו מקווים שתיהנו משירות המודעות המתקדם שלנו ושתמצאו את המקום המושלם
          לאירוח או את הדייר המתאים לנכס שלכם.
          <br /> אל תהססו לכתוב לנו אם יש לכם שאלות או אם אתם זקוקים לסיוע בכל
          נושא.
        </div>
        <div className="screen_contactUs_l">
          <SendMessage
            data={detailsUsers}
            subject={`פניה מדף נחיתה`}
            url={`${process.env.REACT_APP_SERVER_URL}/api/sendMessage/`}
            category={"management"}
            placeholder={
              "נשמח לשמוע מכם כל הערה, הארה, בקשה, שיפור וכל העולה על דעתכם ... 😊"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ScreenContactUs;
