import "./apartmentShow.css";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import Location from "../../components/ApartmentShowComponent/location";
import CalendarComponent from "../../components/CalendarComponent/CalendarComponent";
import BoxHeader from "../../components/Box&Header/Box&Header";
import SliderImage from "../../components/sliderImage";
import Info from "../../components/ApartmentShowComponent/info";
import Contact from "../../components/contact/contact";
import Header from "./header/header";
import MoreApartment from "../../components/moreApartment/moreApartment";
import SendMessage from "../../components/sendMessage/sendMessage";
import { PostToServerLoading } from "../../components/getData";
import MaintenanceScreen from "../../components/MaintenanceScreen/MaintenanceScreen";
import { AppContext } from "../../variable-Context";
import { Button } from "../../components/Input_select_button/Input_select_button";
import HamburgerAnimation from "../../components/HamburgerAnimation/HamburgerAnimation";

function ApartmentShow() {
  const { id } = useParams();
  const { innerWidth } = useContext(AppContext);

  const [error, setError] = useState();
  const [response, setResponse] = useState();
  const [isOpenContact, setIsOpenContact] = useState(false);

  const myRef = useRef(null);

  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

  const apartmentShow = response?.data[0];

  const myButtonRef = useRef(null);

  useEffect(() => {
    if (isOpenContact === true) {
      myButtonRef.current.focus();
    }
  }, [isOpenContact]);

  return (
    <PostToServerLoading
      route={`${process.env.REACT_APP_SERVER_URL}/api/apartmentShow/${id}`}
      error={setError}
      response={setResponse}
      content={
        response?.status === 200 && response?.data?.length === 0 ? (
          <MaintenanceScreen
            content={
              <div>
                המודעה לא נמצאה.
                <br />
                יתכן כי נמחקה או אינה בתוקף כבר.{" "}
              </div>
            }
          />
        ) : response?.data.length > 0 ? (
          <div className="apartmentShow_forHomePage">
            <div className="divHeader">
              <Header apartment={apartmentShow} />
            </div>

            <div className="container_responsive">
              <div className="container_apartmentShow">
                {innerWidth > 600 ? (
                  <div className="div_contact_web">
                    <Contact
                      apartment={apartmentShow}
                      executeScroll={executeScroll}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="div_buttton_isOpenContact">
                      <div
                        className={`buttton_hamburger ${
                          !isOpenContact ? "visible" : ""
                        }`}
                      >
                        <Button
                          onClick={() => {
                            setIsOpenContact(true);
                          }}
                          borderRadius={"50%"}
                          title={
                            <div style={{ width: "20px", height: "16px" }}>
                              <HamburgerAnimation hamburger={isOpenContact} />
                            </div>
                          }
                          padding={"1.5rem 1.2rem"}
                        />
                      </div>
                      <Button
                        onClick={() => {
                          setIsOpenContact(false);
                        }}
                        borderRadius={"50%"}
                        title={
                          <div style={{ width: "20px", height: "16px" }}>
                            <HamburgerAnimation hamburger={isOpenContact} />
                          </div>
                        }
                        padding={"1.5rem 1.2rem"}
                      />

                      <div
                        className={`div_contact_mobile ${
                          isOpenContact ? "visible" : ""
                        }`}
                        ref={myButtonRef}
                        onBlur={() => setIsOpenContact(false)}
                        tabIndex="0"
                      >
                        <Contact
                          apartment={apartmentShow}
                          executeScroll={executeScroll}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  {apartmentShow?.images?.length > 0 ? (
                    <div className="div_images_slider">
                      <SliderImage
                        listImages={apartmentShow}
                        isAutoPlaying={true}
                        hasSizeButton="bottomRight"
                        hasThumbnails={true}
                      />
                    </div>
                  ) : null}

                  <BoxHeader
                    width={"100%"}
                    content={<Info apartmentShow={apartmentShow} />}
                    label={"מידע"}
                  />

                  <div className="divCommponent-SendMessage-Calendar ">
                    <div ref={myRef}>
                      <BoxHeader
                        width={"100%"}
                        content={
                          <SendMessage
                            data={apartmentShow}
                            subject={apartmentShow?.nameApartment}
                            url={`${process.env.REACT_APP_SERVER_URL}/api/sendMessage/`}
                            category={"apartment"}
                          />
                        }
                        label={"שלח הודעה למקום האירוח"}
                      />
                    </div>
                    <BoxHeader
                      width={"100%"}
                      content={
                        <CalendarComponent dateBusy={apartmentShow?.dateBusy} />
                      }
                      label={"תאריכים פנויים"}
                    />
                  </div>

                  <BoxHeader
                    width={"100%"}
                    content={<Location apartmentShow={apartmentShow} />}
                    label={"מפת האיזור"}
                  />

                  <BoxHeader
                    width={"100%"}
                    label={`דירות נוספות באיזור ה${apartmentShow?.area}`}
                    content={<MoreApartment area={apartmentShow?.area} />}
                    border="5px 0 0 0"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : error ||
          error?.response?.status === 500 ||
          error?.response?.status === 400 ? (
          <MaintenanceScreen
            content={
              <div>
                שגיאת נתונים.
                <br />
                נסו שוב במועד מאוחר יותר.
              </div>
            }
          />
        ) : null
      }
    />
  );
}

export default ApartmentShow;
