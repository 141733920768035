import React, { useState, useEffect, useContext } from "react";
import { PostToServer, PutToServer } from "./components/getData";
import { AppContext } from "./variable-Context";

const InitialLoading = () => {
  const {
    innerWidth,
    setinnerWidth,
    detailsUsers,
    setRegisteredUser,
    setIsManager,
    setListIDForFavorite,
    listIDForFavorite,
    setResponseFavoriteApartments,
    setResponseFavoriteReplacementSearch,
  } = useContext(AppContext);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setinnerWidth(window.innerWidth);
    }
    function handleResize() {
      setinnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  }, [innerWidth]);

  useEffect(() => {
    setRegisteredUser(detailsUsers ? true : false);
    setIsManager(detailsUsers ? detailsUsers?.manager : false);
    setListIDForFavorite(detailsUsers?.myFavorites);
  }, [detailsUsers]);

  useEffect(() => {
    detailsUsers &&
      PutToServer(
        `${process.env.REACT_APP_SERVER_URL}/api/editUser/${detailsUsers?._id}`,
        {
          myFavorites: listIDForFavorite,
        }
      );

    detailsUsers &&
      listIDForFavorite !== undefined &&
      PostToServer(
        `${process.env.REACT_APP_SERVER_URL}/api/listOfFavoriteForApartment/`,
        listIDForFavorite,
        null,
        setResponseFavoriteApartments
      ) &&
      PostToServer(
        `${process.env.REACT_APP_SERVER_URL}/api/listOfFavoriteForReplacementSearch/`,
        listIDForFavorite,
        null,
        setResponseFavoriteReplacementSearch
      );
  }, [listIDForFavorite]);

  return null;
};

export default InitialLoading;
