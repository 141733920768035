import React, { useContext } from "react";
import { AppContext } from "../../variable-Context";
import SelectNavigate from "./SelectNavigate/SelectNavigate";

function SelectAreaHomePage() {
  const { setFilterApartments } = useContext(AppContext);

  const listArea = [
    { title: "דירות בכל הארץ", value: "" },
    { title: "דירות באיזור הצפון", value: "צפון" },
    { title: "דירות באיזור המרכז", value: "מרכז" },
    { title: "דירות באיזור ירושלים", value: "ירושלים" },
    { title: "דירות באיזור הדרום", value: "דרום" },
  ];

  return (
    <SelectNavigate
      listNavigate={listArea}
      label={"דירות להשכרה"}
      link={"/"}
      setNavigate={setFilterApartments}
      keyFilter={"area"}
    />
  );
}

export default SelectAreaHomePage;
