import "./SelectNavigate.css";
import { DivOpenHover } from "../../Input_select_button/Input_select_button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useLocation } from "react-router-dom";

function SelectNavigate({ listNavigate, label, link, setNavigate, keyFilter }) {
  const location = useLocation();

  return (
    <div className="select_Navigate">
      {location?.pathname === link ? (
        <DivOpenHover
          label={
            <div className="select_navigate_label_active">
              <Link to={link} style={{ width: "100%" }}>
                <div className="view_navigate">{label}</div>
              </Link>
              <div className="icon_arrow_down_select_navigate">
                <ExpandMoreIcon
                  sx={{
                    fontSize: "2.4rem",
                    color: "white",
                  }}
                />
              </div>
            </div>
          }
          width="100%"
          content={
            <div className="div_select_navigate">
              {listNavigate?.map((item, index) => {
                return (
                  <Link to={link} key={index}>
                    <div
                      className="div_btn_navigate"
                      onClick={() => {
                        setNavigate({ [keyFilter]: item.value });
                      }}
                    >
                      {item.title}{" "}
                    </div>
                  </Link>
                );
              })}
            </div>
          }
        />
      ) : (
        <div className="select_navigate_label">
          <Link to={link} style={{ width: "100%" }}>
            <div className="view_navigate">{label}</div>
          </Link>
        </div>
      )}
    </div>
  );
}

export default SelectNavigate;
