import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import RoomIcon from "@mui/icons-material/Room";
import Maps from "../maps/maps";

const Location = ({ apartmentShow }) => {
  let mql = window.matchMedia("(max-width: 600px)");

  const [lat, setLat] = useState();
  const [lng, setLng] = useState();

  const address = `${apartmentShow?.city}, ${apartmentShow?.street}`;

  Geocode.setApiKey(process.env.REACT_APP_GEOCODE_APIKEY);

  Geocode.setLanguage("he");

  Geocode.setLocationType("ROOFTOP");

  useEffect(() => {
    Geocode.fromAddress(`${address}`).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLat(lat);
        setLng(lng);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [address]);

  const AnyReactComponent = ({ icon, text }) => <div>{icon}</div>;

  return (
    <div style={{ height: mql.matches ? "250px" : "400px" }}>
      {lat !== undefined && lng !== undefined ? (
        <Maps
          cityName={apartmentShow?.city}
          streetName={apartmentShow?.street}
          propslat={lat}
          propslng={lng}
        />
      ) : (
        <div>loading...</div>
      )}
    </div>
  );
  // <GoogleMapReact
  //   style={{ height: mql.matches ? "250px" : "400px" }}
  //   bootstrapURLKeys={{
  //     key: process.env.REACT_APP_GEOCODE_APIKEY,
  //   }}
  //   center={{ lat: lat, lng: lng }}
  //   defaultZoom={15}
  // >
  //   <AnyReactComponent
  //     lat={lat}
  //     lng={lng}
  //     icon={
  //       <RoomIcon
  //         style={{
  //           fontSize: "4rem",
  //           position: "relative",
  //           bottom: "40px",
  //           left: "0px",
  //           color: "red",
  //         }}
  //       />
  //     }
  //   />
  // </GoogleMapReact>
};

export default Location;
