import Tabs from "../../components/tabs/tabs";
import ListApartments from "./ListApartments";
import Users from "./users";
import Messages from "./messages";

const Manager = () => {
  return (
    <div className="container_responsive">
      <Tabs
        label0="מודעות"
        label1="רשימת משתמשים"
        label2="הודעות"
        Tab1={<ListApartments />}
        Tab2={<Users />}
        Tab3={
          <Tabs
            label0="הודעות לבעלי דירות"
            label1="הודעות למחפשי דירות"
            label2="כתבו לנו"
            Tab1={<Messages category={"apartment"} />}
            Tab2={<Messages category={"replacementSearch"} />}
            Tab3={<Messages category="management" />}
          />
        }
      />
    </div>
  );
};

export default Manager;
