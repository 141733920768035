import "./detailsSearchApartment.css";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  Input,
  Select,
  InputSelect,
  TextArea,
} from "../../Input_select_button/Input_select_button";
import ChooseMultipleCity from "../../ChooseMultipleCity/ChooseMultipleCity";

function DetailsSearchApartment({
  formik,
  onchange,
  itemForEdit,
  chooseCity,
  setChooseCity,
  listCityOptions,
  setListCityOptions,
  setApartment,
  apartment,
}) {
  const [listCity, setListCity] = useState([]);
  const [listCityFilter, setListCityFilter] = useState([]);
  const [valueCity, setValueCity] = useState("");

  useEffect(() => {
    axios
      .get(
        "https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=50000"
      )
      .then((response) => {
        setListCity(response.data.result.records);
      });
  }, []);

  const split = (item) =>
    item
      .split(")שבט(")
      .join("")
      .split(")יישוב(")
      .join("")
      .split(")")
      .join(" - ")
      .split("(")
      .join("")
      .split("-")
      .join(" - ")
      .split("  ")
      .join("");

  let pushListCityFilter = [];

  useEffect(() => {
    if (valueCity.length > 1) {
      listCity.forEach((list) => {
        if (
          list.שם_ישוב
            .toLocaleLowerCase()
            .search(valueCity.toLocaleLowerCase()) > -1
        ) {
          pushListCityFilter.push(list);
          setListCityFilter(pushListCityFilter);
        } else if (pushListCityFilter.length < 1) {
          setListCityFilter([{ שם_ישוב: "שם לא קיים" }]);
        }
      });
    } else {
      setListCityOptions(false);
      setListCityFilter([]);
    }
  }, [valueCity]);

  const model = [
    { value: "1", title: "השכרה" },
    { value: "2", title: "החלפה" },
    { value: "3", title: "השכרה / החלפה" },
  ];

  const area = [
    { value: "איזור ירושלים", title: "איזור ירושלים" },
    { value: "איזור הדרום", title: "איזור הדרום" },
    { value: "איזור המרכז", title: "איזור המרכז" },
    { value: "איזור הצפון", title: "איזור הצפון" },
  ];

  return (
    <div className="div-all-input">
      <div className="div-all-input div_details_listCity">
        <Select
          label={"אני מעוניין ב..."}
          name={"model"}
          onChange={onchange}
          formikErr={formik.errors.model}
          options={model}
          width={"90%"}
          defaultValue={itemForEdit ? itemForEdit.model : ""}
        />

        <div />
        <div />

        <Select
          label={"בחר איזור בארץ"}
          name={"areaSearch"}
          onChange={onchange}
          formikErr={formik.errors.areaSearch}
          options={area}
          width={"90%"}
          defaultValue={itemForEdit ? itemForEdit.areaSearch : ""}
        />

        <ChooseMultipleCity
          chooseCity={chooseCity}
          setChooseCity={setChooseCity}
          listCityOptions={listCityOptions}
          setListCityOptions={setListCityOptions}
        />
      </div>

      {/* <br /> */}
      <div className="div-all-input div_details">
        <Input
          label={"מס' חדרים"}
          name={"roomsSearch"}
          onChange={onchange}
          defaultValue={itemForEdit ? itemForEdit.roomsSearch : ""}
          formikErr={formik.errors.roomsSearch}
          width={"90%"}
        />

        <Input
          label={"מס' מיטות"}
          name={"bedsSearch"}
          onChange={onchange}
          defaultValue={itemForEdit ? itemForEdit.bedsSearch : ""}
          formikErr={formik.errors.bedsSearch}
          width={"90%"}
        />

        <TextArea
          label={"מיטת תינוק"}
          width={"90%"}
          height={"2.4rem"}
          name={"babyBedSearch"}
          content={
            <div className="div_baby_bed">
              <div className="div_button_baby_bed">
                <input
                  type="radio"
                  name="babyBedSearch"
                  value={1}
                  onChange={onchange}
                  defaultChecked={
                    itemForEdit?.babyBedSearch === 1 ? true : false
                  }
                />{" "}
                נצרך
              </div>
              <div className="div_button_baby_bed">
                <input
                  type="radio"
                  name="babyBedSearch"
                  value={0}
                  onChange={onchange}
                  defaultChecked={
                    itemForEdit?.babyBedSearch === 0 ? true : false
                  }
                />{" "}
                אין צורך
              </div>
            </div>
          }
          formikErr={formik.errors.babyBedSearch}
          contentEditable={false}
        />

        {/* {apartment.model === "1" || apartment.model === "3" ? (
          <Input
            label={"מחיר"}
            name={"priceSearch"}
            onChange={onchange}
            defaultValue={itemForEdit ? itemForEdit.priceSearch : ""}
            formikErr={formik.errors.priceSearch}
            width={"90%"}
          />
        ) : null} */}
      </div>

      <div className="div-all-input">
        <TextArea
          label={"ספרו לנו מה אתם מחפשים"}
          name={"longSearch"}
          onInput={(e) => {
            setApartment({
              ...apartment,
              longSearch: e.currentTarget.textContent,
            });
          }}
          width={"92%"}
          height={"14rem"}
          content={itemForEdit ? itemForEdit.longSearch : ""}
          contentEditable={true}
          placeholder={
            "ספרו מה חשוב לכם שיהיה בדירה שאתם מחפשים, נקיון, נוף, חצר, בתי כנסת, מכולת, תחבורה ציבורית, מקומות בילוי, וכו'..."
          }
        />
      </div>
    </div>
  );
}

export default DetailsSearchApartment;
