import React, { useState } from "react";
import Table from "../../components/table/table";
import { PostToServerLoading } from "../../components/getData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Messages({ category }) {
  const [messages, setMessages] = useState([]);
  const [sortDate, setSortDate] = useState(false);
  const [sortSubject, setSortSubject] = useState(false);

  const sortDateFunc = () => {
    setSortDate(!sortDate);
  };
  const funcSortSubject = () => {
    setSortSubject(!sortSubject);
    messages.sort((a, b) =>
      sortSubject
        ? b.subject.localeCompare(a.subject)
        : a.subject.localeCompare(b.subject)
    );
  };

  const valueHeader = [
    {
      title: category !== "management" ? "מייל בעל הדירה" : "מייל המשתמש",
      width: "10%",
    },
    { title: "שם פונה", width: "10%" },
    { title: "פרטי חזרה לפונה", width: "10%" },
    { title: "תוכן ההודעה", width: "50%" },
  ];

  return (
    <div>
      <PostToServerLoading
        route={`${process.env.REACT_APP_SERVER_URL}/api/messages/`}
        data={setMessages}
        content={
          <Table
            valueHeader={
              <>
                <th style={{ width: "10%" }} onClick={sortDateFunc}>
                  {"זמן הפנייה"}{" "}
                  <ExpandMoreIcon
                    sx={{
                      fontSize: "2rem",
                      marginInline: "10px",
                      color: "white",
                      transform: sortDate ? "rotate(180deg)" : "",
                    }}
                  />
                </th>
                {category !== "management" && (
                  <th style={{ width: "10%" }} onClick={funcSortSubject}>
                    {"בעניין"}{" "}
                    <ExpandMoreIcon
                      sx={{
                        fontSize: "2rem",
                        marginInline: "10px",
                        color: "white",
                        transform: sortSubject ? "rotate(180deg)" : "",
                      }}
                    />
                  </th>
                )}
                {valueHeader.map((item, i) => (
                  <th key={i} style={{ width: item.width }}>
                    {item.title}
                  </th>
                ))}
              </>
            }
            valueBody={messages
              ?.sort((a, b) =>
                !sortDate
                  ? new Date(b.date) < new Date(a.date)
                    ? -1
                    : 1
                  : new Date(a.date) > new Date(b.date)
                  ? 1
                  : -1
              )
              .map((list) =>
                category === list.category ? (
                  <tr key={list._id}>
                    <td>{new Date(list.date).toLocaleString()}</td>
                    {category !== "management" && (
                      <td>
                        {" "}
                        <a
                          className="link_PrivacyPolicy"
                          href={`/apartmentShow/${list.idApartment}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {list.subject}
                        </a>
                      </td>
                    )}
                    <td>
                      {category !== "management"
                        ? list.mailApartment
                        : list.mailUser}
                    </td>
                    <td>{list.nameUser}</td>
                    <td>{list.phoneMailBack}</td>
                    <td>{list.message}</td>
                  </tr>
                ) : null
              )}
          />
        }
      />
    </div>
  );
}

export default Messages;
