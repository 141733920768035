import "./MaintenanceScreen.css";
import Logo from "../Logo/Logo";

const MaintenanceScreen = ({ content }) => {
  return (
    <div className="MaintenanceScreen">
      <div className="div_logo_maintenance">
        <Logo />
      </div>
      {content}
      <br />
      למעבר לעמוד הבית{" "}
      <a href="/" style={{ textDecoration: "underline" }}>
        לחץ כאן
      </a>{" "}
    </div>
  );
};

export default MaintenanceScreen;
