import { useContext, useState } from "react";
import SelectNavigate from "./SelectNavigate/SelectNavigate";
import { AppContext } from "../../variable-Context";

function SelectSearchApartment() {
  const { setFilterReplacementSearch } = useContext(AppContext);
  const listSearchApartment = [
    { title: "לכל מודעות החיפוש", value: "" },
    // { title: "חיפוש דירה להשכרה / החלפה", value: "3" },
    { title: "חיפוש דירה להשכרה", value: "1" },
    { title: "חיפוש דירה להחלפה", value: "2" },
  ];

  return (
    <SelectNavigate
      listNavigate={listSearchApartment}
      label={
        <div>
          מודעות חיפוש דירות
          <br />
          <div className="label_small">השכרה / החלפה</div>{" "}
        </div>
      }
      link={"/searchApartment"}
      setNavigate={setFilterReplacementSearch}
      keyFilter={"model"}
    />
  );
}

export default SelectSearchApartment;
