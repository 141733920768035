export const listCitiesByArea = async (
  listCityForSearch,
  keyCity,
  listAreaForSearch,
  keyArea,
  setListCity
) => {
  const listCitiesForSplice = [];

  for (let index = 0; index < listCityForSearch?.length; index++) {
    if (
      listCityForSearch[index]?.[keyCity] !== undefined &&
      listCityForSearch[index]?.[keyCity] !== null &&
      listCityForSearch[index]?.[keyCity] !== ""
    ) {
      if (
        listAreaForSearch?.[keyArea] === "" ||
        listAreaForSearch?.[keyArea] === undefined
      ) {
        listCitiesForSplice.push({
          title: listCityForSearch[index]?.[keyCity],
          value: listCityForSearch[index]?.[keyCity],
        });
      } else
        listAreaForSearch?.[keyArea] === listCityForSearch[index]?.[keyArea] &&
          listCitiesForSplice.push({
            title: listCityForSearch[index]?.[keyCity],
            value: listCityForSearch[index]?.[keyCity],
          });
    }
    for (var i = 0; i < listCitiesForSplice?.length; ++i) {
      for (var j = i + 1; j < listCitiesForSplice?.length; ++j) {
        if (listCitiesForSplice[i].title === listCitiesForSplice[j].title)
          listCitiesForSplice.splice(i--, 1);
      }
    }
  }

  setListCity(listCitiesForSplice.sort((a, b) => (a.title > b.title ? 1 : -1)));
};
