import "./ScreenPersonalPage.css";
import imgCalander from "./calander.png";
import imgEditPost from "./editPost.png";
import imgFavorite from "./favorite.png";
import imgMessages from "./messages.png";
import imgNotification from "./notification.png";

const ScreenPersonalPage = () => {
  return (
    <div className="screen_personal_page scroll_designed">
      <div className="screen_personal_page_p">
        <h1>האזור האישי</h1>
      </div>
      <div className="screen_personal_page_p">
        <img src={imgEditPost} alt="" />
        באיזור האישי תוכלו לנהל את המודעות שלכם בצורה קלה ונוחה. <br />
        תוכלו למחוק לערוך או להקפיא את המודעה שלכם בכל זמן שתרצו, <br />
        המודעה תישאר במערכת שלנו כדי שתוכלו להפעיל <br />
        ולהציג אותה שוב בזמן המתאים לכם.
      </div>
      <div className="screen_personal_page_p">
        <img src={imgCalander} alt="" />
        תוכלו לסמן תאריכים שבהם <br />
        מקום האירוח שלכם תפוס.
      </div>
      <div className="screen_personal_page_p">
        {" "}
        <img src={imgNotification} alt="" />
        בנוסף, תוכלו להצטרף לשירות הודעה אוטומטית <br />
        שבו תקבלו הודעה בכל פעם שמישהו מעלה <br />
        מודעת חיפוש באיזור של מקום האירוח שלכם.
        <br /> זה יסייע לכם להגיע ללקוחות פוטנציאלים ולפניות חדשות.
      </div>
      <div className="screen_personal_page_p">
        {" "}
        <img src={imgFavorite} alt="" />
        תוכלו לצפות במודעות שהוספתם למועדפים.
      </div>
      <div className="screen_personal_page_p">
        {" "}
        <img src={imgMessages} alt="" />
        ההודעות והפניות שתקבלו לגבי המודעות שפרסמתם <br />
        יישמרו באיזור האישי שלכם,
        <br />
        כך שתמיד תוכלו לגשת אליהם ולצפות בהם בכל עת. <br />
        (בנוסף להודעה שתשלח למייל האישי שלכם)
      </div>
      <div className="screen_personal_page_p">
        {/* ועוד מגוון אפשרויות מעניינות מחכות לכם באזור האישי. */}
      </div>
    </div>
  );
};

export default ScreenPersonalPage;
