import "./Description.css";
import { useState, useEffect } from "react";
import {
  TextArea,
  Input,
  Checkbox,
} from "../../Input_select_button/Input_select_button";

function SpecialApartment({
  setApartment,
  apartment,
  formik,
  itemForEdit,
  onchange,
}) {
  const [special, setSpecial] = useState(
    itemForEdit ? itemForEdit?.special : []
  );
  const [time, setTime] = useState(itemForEdit ? itemForEdit?.times : []);

  const specials = [
    "חצר",
    "מרפסת",
    "נוף",
    "מזגן",
    "מטבחון",
    "מינימרקט",
    "ארוחת בוקר",
    "מיטת תינוק",
    "גישה לנכים",
    "מגבות",
    "מצעים",
    "פלטת שבת",
    "מיחם לשבת",
    "בית כנסת קרוב",
    "קרוב לים",
    "תחבורה ציבורית",
    "ריהוט גן",
    "פינות ישיבה בחצר",
    "ג'קוזי",
    "מכונת אספרסו",
    "בריכה פרטית",
    "ערסל",
    "מתחם לברביקיו",
    "פינת קפה ותה",
    "קומקום חשמלי",
    "מקרר",
    "מיקרוגל",
    "כיריים חשמליות",
    "נדנדה",
    "מנגל",
    "חנייה פרטית",
  ];

  const times = [
    { time: `שבתות / סופ"ש`, value: "1" },
    { time: "חגים", value: "2" },
    { time: "בין הזמנים", value: "3" },
    { time: `כל השנה`, value: "4" },
  ];
  return (
    <div className="div-all-input">
      {/* <div className="div-all-input div_description"> */}
      {/* <TextArea
          label={"תיאור קצר"}
          onInput={(e) => {
            setApartment({
              ...apartment,
              short: e.currentTarget.textContent,
            });
          }}
          placeholder={"כתבו תיאור קצר על מקום האירוח (עד 100 תווים)"}
          formikErr={formik.errors.short}
          width={"92%"}
          height={"14rem"}
          content={itemForEdit ? itemForEdit.short : ""}
          px={true}
        /> */}

      <TextArea
        label={"תיאור"}
        onInput={(e) => {
          setApartment({
            ...apartment,
            long: e.currentTarget.textContent,
          });
        }}
        placeholder={
          "ספרו בהרחבה על מקום האירוח יתרונות, נוף, חצר, בתי כנסת, מכולת, תחבורה ציבורית, מקומות בילוי, וכל מה שיכול לעניין את האורחים שלכם."
        }
        formikErr={formik.errors.long}
        width={"92%"}
        height={"14rem"}
        content={itemForEdit ? itemForEdit.long : ""}
        contentEditable={true}
        name={"long"}
      />
      {/* </div> */}

      <TextArea
        label={"המיוחדים שלנו"}
        formikErr={formik.errors.special}
        width={"92%"}
        height={"auto"}
        name={"special"}
        content={
          <div className="special">
            {specials.map((specials, index) => (
              <Checkbox
                key={index}
                label={specials}
                value={specials}
                checked={special?.map((special) => special).includes(specials)}
                onChange={(event) => {
                  let test = special.find((e) => e === event.target.value);
                  if (!test) {
                    special.push(event.target.value);
                    setApartment({
                      ...apartment,
                      special: special,
                    });
                  } else {
                    let filter = special.filter(
                      (e) => e !== event.target.value
                    );
                    setSpecial(filter);
                    setApartment({
                      ...apartment,
                      special: filter,
                    });
                  }
                }}
              />
            ))}
          </div>
        }
      />

      <TextArea
        label={"תקופות השכרה"}
        formikErr={formik.errors.times}
        width={"92%"}
        height={"auto"}
        name={"times"}
        content={
          <div className="special">
            {times.map((times, index) => (
              <Checkbox
                key={index}
                label={times.time}
                value={times.value}
                checked={time?.map((time) => time).includes(times.value)}
                onChange={(event) => {
                  let test = time.find((e) => e === event.target.value);
                  if (!test) {
                    time.push(event.target.value);
                    setApartment({
                      ...apartment,
                      times: time,
                    });
                  } else {
                    let filter = time.filter((e) => e !== event.target.value);
                    setTime(filter);
                    setApartment({
                      ...apartment,
                      times: filter,
                    });
                  }
                }}
              />
            ))}
          </div>
        }
      />
      <Input
        label={"קישור לאתר"}
        name={"webSite"}
        onChange={onchange}
        defaultValue={itemForEdit ? itemForEdit.webSite : ""}
        placeholder={"ניתן לצרף קישור לאתר של מקום האירוח"}
        width={"92%"}
      />
    </div>
  );
}

export default SpecialApartment;
