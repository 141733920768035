import "./detailsChangeApartment.css";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  Input,
  Select,
  TextArea,
} from "../../Input_select_button/Input_select_button";
import ChooseCityStreet from "../../ChooseCityStreet/ChooseCityStreet";

function DetailsChangeApartment({
  formik,
  onchange,
  itemForEdit,
  apartment,
  setApartment,
  chooseCityChange,
  setChooseCityChange,
  valueCityChange,
  setValueCityChange,
  valueStreetChange,
  setValueStreetChange,
  chooseStreetChange,
  setChooseStreetChange,
}) {
  useEffect(() => {
    setApartment({
      ...apartment,
      cityChange: chooseCityChange,
      streetChange: chooseStreetChange,
    });
  }, [chooseCityChange, chooseStreetChange]);

  const area = [
    { value: "איזור ירושלים", title: "איזור ירושלים" },
    { value: "איזור הדרום", title: "איזור הדרום" },
    { value: "איזור המרכז", title: "איזור המרכז" },
    { value: "איזור הצפון", title: "איזור הצפון" },
  ];

  return (
    <div className="div-all-input">
      <div className="div-all-input div_details_listCity">
        <Select
          label={"בחר איזור בארץ"}
          name={"areaChange"}
          onChange={onchange}
          formikErr={formik.errors.areaChange}
          options={area}
          width={"90%"}
          defaultValue={itemForEdit ? itemForEdit.areaChange : ""}
        />
        <ChooseCityStreet
          valueCity={valueCityChange}
          setValueCity={setValueCityChange}
          chooseCity={chooseCityChange}
          setChooseCity={setChooseCityChange}
          valueStreet={valueStreetChange}
          setValueStreet={setValueStreetChange}
          chooseStreet={chooseStreetChange}
          setChooseStreet={setChooseStreetChange}
          itemForEdit={itemForEdit}
          formik={formik}
          keyFormikCity={"cityChange"}
          keyFormikStreet={"streetChange"}
        />
      </div>

      <br />
      <div className="div-all-input div_details">
        <Input
          label={"מס' חדרים"}
          name={"roomsChange"}
          onChange={onchange}
          defaultValue={itemForEdit ? itemForEdit.roomsChange : ""}
          formikErr={formik.errors.roomsChange}
          width={"90%"}
        />

        <Input
          label={"מס' מיטות"}
          name={"bedsChange"}
          onChange={onchange}
          defaultValue={itemForEdit ? itemForEdit.bedsChange : ""}
          formikErr={formik.errors.bedsChange}
          width={"90%"}
        />

        <TextArea
          label={"מיטת תינוק"}
          width={"90%"}
          height={"2.4rem"}
          name={"babyBedChange"}
          content={
            <div className="div_baby_bed">
              <div className="div_button_baby_bed">
                <input
                  type="radio"
                  name="babyBedChange"
                  value={1}
                  onChange={onchange}
                  defaultChecked={
                    itemForEdit?.babyBedChange === 1 ? true : false
                  }
                />{" "}
                יש
              </div>
              <div className="div_button_baby_bed">
                <input
                  type="radio"
                  name="babyBedChange"
                  value={0}
                  onChange={onchange}
                  defaultChecked={
                    itemForEdit?.babyBedChange === 0 ? true : false
                  }
                />{" "}
                אין
              </div>
            </div>
          }
          formikErr={formik.errors.babyBedChange}
          contentEditable={false}
        />
      </div>

      <div className="div-all-input">
        <TextArea
          label={"ספרו לנו מה אתם מציעים בדירה שלכם"}
          onInput={(e) => {
            setApartment({
              ...apartment,
              longChange: e.currentTarget.textContent,
            });
          }}
          formikErr={formik.errors.long}
          width={"92%"}
          height={"14rem"}
          content={itemForEdit ? itemForEdit.longChange : ""}
          contentEditable={true}
          placeholder={
            "ספרו על הדירה שלכם, יתרונות, נוף, חצר, בתי כנסת, מכולת, תחבורה ציבורית, מקומות בילוי, וכל מה שיכול לעניין את האורחים שלכם."
          }
        />
      </div>
    </div>
  );
}

export default DetailsChangeApartment;
