import "./SearchApartmentShowComponent.css";
import Baner from "../../components/baner";
import Hebcal from "hebcal";
import gematriya from "gematriya";
import { Room } from "@mui/icons-material/";
import SendMessage from "../sendMessage/sendMessage";

function Info({ data, sendMessage }) {
  const testModel = data?.model === "1" ? false : true;

  const Dates = (dateBusy) => {
    const DateTile = (date) => {
      const hebDate = Hebcal.HDate(date);
      return (
        <div className="list_date_Tile">
          <div className="list_date_heb">
            {" "}
            {gematriya(hebDate.getDate())}
            <div className="list_month_heb">{hebDate.getMonthName("h")}</div>
          </div>
          <div className="list_date_cal">{`${date.getDate()}/${
            date.getMonth() + 1
          }`}</div>
        </div>
      );
    };

    return (
      dateBusy?.length > 0 && (
        <div className="div_date">
          לתאריכים:
          <br />
          <div className="div_all_date">
            {dateBusy
              .sort((a, b) => (b > a ? -1 : 1))
              .map((date, index) => (
                <div key={index}>{DateTile(new Date(date))}</div>
              ))}
          </div>
        </div>
      )
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <div className={testModel ? "info" : "info_rent_only"}>
        <div className="div_baner_r">
          <Baner
            content={`מחפשים מקום אירוח ב${data?.areaSearch}`}
            color={"var(--white)"}
          />
        </div>
        {testModel ? (
          <div className="div_baner_l">
            <Baner
              content={`מציעים בתמורה דירה ב${data?.areaChange}`}
              color={"var(--white)"}
            />
          </div>
        ) : null}

        <div className="info_content_r">
          <div className="div_all_city">
            {data?.citySearch?.length > 0 &&
              data?.citySearch.map((city, index) => (
                <div key={index} className="city_name">
                  <Room style={{ fontSize: "1.7rem", color: "#ad0000" }} />
                  {city}{" "}
                </div>
              ))}
          </div>
          {data?.longSearch && (
            <div className="div_description">
              <div style={{ color: "var(--blue-lite)" }}>מה אנחנו מחפשים?</div>
              {data?.longSearch}
            </div>
          )}{" "}
          <div className="div_details">
            {data?.roomsSearch && (
              <div className="div_details_1">
                <div className="div_details_2">חדרים: </div>
                <div>{data?.roomsSearch}</div>
              </div>
            )}

            {data?.bedsSearch && (
              <div className="div_details_1">
                <div className="div_details_2"> מיטות: </div>
                <div>{data?.bedsSearch}</div>
              </div>
            )}
            {data?.babyBedSearch && (
              <div className="div_details_1">
                <div className="div_details_2"> מיטת תינוק: </div>
                <div>{data?.babyBedSearch === 1 ? "יש צורך" : "אין צורך"}</div>
              </div>
            )}
          </div>
          {data?.dateBusyString ? (
            <div className="div_date">
              לתאריכים:
              <br />
              <div style={{ color: "var(--blue)", fontSize: "1.5rem" }}>
                {data?.dateBusyString}
              </div>
            </div>
          ) : (
            Dates(data?.dateBusy)
          )}
        </div>

        <div className="info_content_l">
          {data?.model === "2" || data?.model === "3" ? (
            <div className="div_baner">
              <div className="div_all_city">
                <div className="city_name">
                  {" "}
                  <Room style={{ fontSize: "1.7rem", color: "#ad0000" }} />
                  {data?.cityChange} רח' {data?.streetChange}
                </div>
              </div>
              {data?.longChange && (
                <div className="div_description">
                  <div style={{ color: "var(--blue-lite)" }}>
                    מה אנחנו מציעים?
                  </div>
                  {data?.longChange}
                </div>
              )}{" "}
              <div className="div_details">
                {data?.roomsChange && (
                  <div className="div_details_1">
                    <div className="div_details_2"> חדרים: </div>
                    <div>{data?.roomsChange}</div>
                  </div>
                )}
                {data?.bedsChange && (
                  <div className="div_details_1">
                    <div className="div_details_2"> מיטות: </div>
                    <div>{data?.bedsChange}</div>
                  </div>
                )}
                {data && (
                  <div className="div_details_1">
                    <div className="div_details_2"> מיטת תינוק: </div>
                    <div>{data?.babyBedChange === 1 ? "יש" : "אין"}</div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {sendMessage && (
            <div className="div_send_message">
              שליחת הודעה למפרסם המודעה <br />
              <SendMessage
                data={data}
                subject={`מודעת חיפוש דירה ל${
                  data?.model === "1"
                    ? "השכרה"
                    : data?.model === "2"
                    ? "החלפה"
                    : "השכרה / החלפה"
                }`}
                url={`${process.env.REACT_APP_SERVER_URL}/api/sendMessage/`}
                category={"replacementSearch"}
              />
            </div>
          )}
        </div>
      </div>{" "}
    </div>
  );
}

export default Info;
