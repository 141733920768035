import "./ScreenHomePage.css";
import imgPostApartment from "./postApartment.png";

const ScreenHomePage = () => {
  return (
    <div className="screen_home_page scroll_designed">
      <div className="img_screen_home_page">
        <img src={imgPostApartment} alt="postApartment" />
      </div>
      <div className="text_screen_home_page">
        <div>ברוכים הבאים לאתר החדש של "וינפש"! </div>
        <div>
          אנחנו שמחים להציג לכם את הפלטפורמה החדשה שלנו, <br />
          המיועדת לפרסום מודעות של משכירי מקומות אירוח
          <br /> ומחפשי מקומות אירוח.{" "}
        </div>
        <div>
          {" "}
          האתר מציע לכם את ההזדמנות לפרסם את מודעתכם בחינם
          <br /> ולהציג את הייחודיות של מקום האירוח שלכם
          <br /> לאלפי מבקרים שמחפשים לשכור מקום אירוח.{" "}
        </div>
        <div>
          {" "}
          אם יש לכם דירה, וילה, צימר או יחידת אירוח להשכרה,
          <br /> או סתם בא לכם לסבלט את הדירה שלכם לחופשות, <br />
          חגים או בין הזמנים, אתם במקום הנכון. באמצעות אתרנו,
          <br /> תוכלו לפרסם מודעה ולהציג את מקומות האירוח שלכם למבקרים המחפשים
          לשכור מקום אירוח.{" "}
        </div>
        <div>
          {" "}
          אם אתם מחפשים דירת נופש מושלמת, יחידת דיור מפוארת, צימר או וילה
          במיקומים שונים ברחבי הארץ, או אפילו מעוניינים להחליף דירה עם משפחה
          אחרת, תוכלו למצוא מגוון רחב של מקומות אירוח יוצאי דופן דרך האתר שלנו.{" "}
        </div>
        <div>
          {" "}
          הצטרפו אלינו עוד היום ותמצאו את המקום המושלם לחוויה נפלאה של אירוח
          ונופש!
        </div>
      </div>
    </div>
  );
};

export default ScreenHomePage;
