import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiTypography from "@mui/material/Typography";

function Accordion({ boxheader, box }) {
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    margin: "20px 0",
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        <ExpandMoreIcon sx={{ fontSize: "2rem", marginInlineStart: "-10px" }} />
      }
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(180deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));
  const Typography = styled(MuiTypography)(({ theme }) => ({
    // padding: theme.spacing(2),
    // borderTop: "1px solid rgba(0, 0, 0, .125)",
    width: "98%",
    marginInlineStart: "2%",
  }));

  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <div>
        <Accordion expanded={expanded === 1} onChange={handleChange(1)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>
              <div style={style.boxheader}>{boxheader} </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div style={style.box}>{box}</div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default Accordion;

const style = {
  boxheader: {
    // width: "inherit",
    // position: "absolute",
    // top: 0,
    // left: "2%",
    // height: "200px",
  },
  box: {
    zIndex: -5,
  },
};

// const accordion = [];
// const [tris, setTris] = useState([]);
// useEffect(() => {
//   for (let index = 0; index < 5; index++) {
//     accordion.push({ index: index, tris: false });
//   }
//   setTris(accordion);
// }, []);

// {tris?.map((item, index) => (
//   <div key={index}>
//     <button
//       onClick={() => {
//         const newState = tris.map((obj) => {
//           if (obj.index === index) {
//             return { ...obj, index: index, tris: !tris[index].tris };
//           }
//           return obj;
//         });

//         setTris(newState);
//       }}
//     >
//       {index}
//     </button>
//     {tris[index].tris === true ? <div>תריס מספר {index} </div> : null}
//   </div>
// ))}
