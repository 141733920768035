import React from "react";
import "./privacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="container_responsive">
      <div className="privacyPolicy">
        <div className="privacyPolicy_title">תקנון האתר</div>

        <div className="privacyPolicy_content_title">1. תכנים ומידע:</div>
        <div className="privacyPolicy_content">
          1.1. האתר מספק פלטפורמה למפרסמים ולמשתמשים לפרסם דירות נופש, צימרים
          ודירות שניתן להחליף.
        </div>
        <div className="privacyPolicy_content">
          1.2. כל המידע שמתפרסם באתר צריך להיות מדויק ועדכני. המפרסם יואשר
          באחריותו האישית על תכני המידע שהוא מפרסם.
        </div>
        <div className="privacyPolicy_content">
          1.3. האתר אינו אחראי לתכנים שמפורסמים על ידי משתמשים אחרים, אך נמצא
          בזכותו למחוק או לערוך כל תכנים שאינם תואמים את תקנון האתר.
        </div>

        <div className="privacyPolicy_content_title">2. פרטיות:</div>
        <div className="privacyPolicy_content">
          2.1. האתר מתחייב לשמור על פרטיות המשתמשים והמפרסמים ולא לשתף את המידע
          האישי שלהם עם גורם שלישי בלתי מורשה{" "}
        </div>
        <div className="privacyPolicy_content">
          2.2. המפרסם מתחייב לשמור על פרטיות הנתונים האישיים שלו ולא לחשוף אותם
          למשתמשים אחרים ללא הסכמתם המוסכמת מראש.{" "}
        </div>
        <div className="privacyPolicy_content">
          2.3. האתר יכול להשתמש במידע אישי של המשתמשים והמפרסמים לצורך שיווק
          ושידור מידע אודות שירותיו בלבד.{" "}
        </div>

        <div className="privacyPolicy_content_title">
          3. התקשורת וההתקשרות:{" "}
        </div>
        <div className="privacyPolicy_content">
          3.1. האתר מספק מערכת תקשורת בין המפרסמים לבין המשתמשים המעוניינים
          לשכור או להחליף דירות.{" "}
        </div>
        <div className="privacyPolicy_content">
          3.2. כל התקשורת וההתקשרות בין המשתמשים והמפרסמים על ידי האתר יחולה
          במערכת התקשורת המובנית של האתר והיא צפויה להתבצע באופן יסודי ומקצועי.{" "}
        </div>
        <div className="privacyPolicy_content">
          3.3. האתר לא יהיה אחראי לכל התקשרות או הסכמה שתתקיים בין המשתמשים
          והמפרסמים. כל התקשרות או הסכמה ביניהם מהווה הסכם בלתי תלוי.{" "}
        </div>

        <div className="privacyPolicy_content_title">
          4. אחריות ואי-אחריות:{" "}
        </div>
        <div className="privacyPolicy_content">
          4.1. האתר אינו אחראי לאורח התקנון על עיסוק עסקי או פרטי מצד המשתמשים
          והמפרסמים.{" "}
        </div>
        <div className="privacyPolicy_content">
          4.2. האתר לא יהיה אחראי לכל נזק או אובדן שעלולים להיגרם למשתמשים או
          למפרסמים עקב שימוש בשירותיו.{" "}
        </div>
        <div className="privacyPolicy_content">
          4.3. המפרסם מצהיר כי הוא אחראי באופן מוחלט על תוכן הפרסומות שהוא מעלה
          לאתר ועל העסקאות שהוא יבצע עם משתמשים אחרים.{" "}
        </div>

        <div className="privacyPolicy_content_title">5. הוראות כלליות: </div>
        <div className="privacyPolicy_content">
          5.1. כל השימוש באתר כגון פרסום דירות, תגובות או תגובות לפרסומים, וככל
          פעולה או שימוש שלא יתאים לתקנון האתר והוראותיו עשוי לגרום לחסימת
          המשתמש או למחיקת הפרסומות המועלות לאתר.{" "}
        </div>
        <div className="privacyPolicy_content">
          5.2. האתר שומר לעצמו את הזכות לעדכן את תקנון האתר מעת לעת, והשימוש
          המתמשך באתר לאחר העדכון מהווה הסכמה בלתי תנאית לתקנון המעודכן.{" "}
        </div>
        <div className="privacyPolicy_content">
          5.3. כל הסכם שלא יכתוב בכתב ולא יוסכם בצורה חד משמעית בין הצדדים לא
          יחייב את הצדדים לחובה כלשהי.{" "}
        </div>
        <div className="privacyPolicy_content">
          5.4. התקנון וכל התקנות והוראות המתקנון מוגשות לפי דין המדינה בה תוקפן
          והן עשויות להשתנות מעת לעת בהתאם לדיני המדינה והמגמות המקומיות.{" "}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
