import "./Logo.css";
import mtr from "./mtr.png";

const Logo = ({ titleColor, fontSizeText, fontSizeTitle, sizeImg }) => {
  return (
    <div className="div_logo">
      <div className="text_logo" style={{ fontSize: fontSizeText }}>
        <img
          src={mtr}
          alt=""
          className="imgLogo"
          style={{ width: sizeImg, height: sizeImg }}
        />
        וינפש
        <div
          className="title_logo"
          style={{
            color: titleColor ? titleColor : "var(--blue)",
            fontSize: fontSizeTitle,
          }}
        >
          לנפוש באווירה מתאימה
        </div>
      </div>
      <div className="text_logo" style={{ fontSize: fontSizeText }}>
        וינפש
      </div>
      <div className="text_logo" style={{ fontSize: fontSizeText }}>
        וינפש
      </div>
      <div className="text_logo" style={{ fontSize: fontSizeText }}>
        וינפש
      </div>
    </div>
  );
};

export default Logo;
