import "./BarHeader.css";
import Logo from "../Logo/Logo";
import React, { useContext } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AppContext } from "../../variable-Context";
import { Button } from "../Input_select_button/Input_select_button";
import FavoriteInHeader from "../FavoriteInHeader/FavoriteInHeader";

const BarHeader = () => {
  const {
    registeredUser,
    setRegisteredUser,
    uidFirebase,
    setUidFirebase,
    detailsUsers,
    setDetailsUsers,
    innerWidth,
  } = useContext(AppContext);

  const auth = getAuth();

  const exit = async () => {
    await signOut(auth)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    setDetailsUsers([]);
    setUidFirebase("");
    setRegisteredUser(false);

    window.location.href = "/login/";
  };

  return (
    <div className="bar">
      <a href="/">
        <div className="div_logo_barHeader">
          <Logo />
        </div>
      </a>

      <div className="divBar">
        {innerWidth > 600 && detailsUsers?.manager === true ? (
          <a href={`/manager/${process.env.REACT_APP_URL_MANAGER}`}>
            <Button
              title={"לדף הניהול"}
              padding={"0.5rem 1.5rem"}
              borderRadius={"10px"}
            />
          </a>
        ) : null}

        {innerWidth > 701 && <FavoriteInHeader />}

        {registeredUser ? (
          <div className="divConnect">
            <AccountCircleIcon
              style={{
                fontSize: "4rem",
                fontWeight: 900,
                color: "black",
              }}
            />{" "}
            היי, {detailsUsers?.nameUser}{" "}
            <div className="div_link_exit">
              <Link className="link" to={`/user/${uidFirebase}`}>
                <div className="divConnect_modal">
                  <PowerSettingsNewIcon
                    className="iconEnter"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: 900,
                      color: "green",
                      marginInlineEnd: "5px",
                    }}
                  />{" "}
                  לאיזור האישי
                </div>
              </Link>{" "}
              |{" "}
              <div className="divConnect_modal" onClick={exit}>
                <PowerSettingsNewIcon
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 900,
                    color: "red",
                    marginInlineEnd: "5px",
                  }}
                />
                התנתק
              </div>
            </div>
          </div>
        ) : (
          <Link className="link" to={"/login/"}>
            <div className="divConnect">
              <AccountCircleIcon
                style={{
                  fontSize: "4rem",
                  fontWeight: 900,
                  color: "black",
                }}
              />{" "}
              התחברות / הרשמה{" "}
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default BarHeader;
