import "./ListSearchApartment.css";
import Accordion from "../Accordion";
import React, { useState, useContext, useEffect } from "react";
import { PostToServerLoading } from "../getData";
import RmoveApartment from "./removeApartment";
import HoldApartment from "./holdApartment";
import EditApartment from "./editApartment";
import SearchApartmentShowComponent from "../SearchApartmentShowComponent/SearchApartmentShowComponent";
import { Button } from "../Input_select_button/Input_select_button";
import Baner from "../baner";
import Modal from "../Modal";
import { AppContext } from "../../variable-Context";
import { margin } from "@mui/system";

const ItemApartment = ({ url }) => {
  let mql = window.matchMedia("(max-width: 600px)");

  const [apartments, setApartments] = useState([]);
  const [render, setRender] = useState(false);
  const [itemForEdit, setItemForEdit] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState();

  const { isManager } = useContext(AppContext);

  useEffect(() => {
    if (itemForEdit !== undefined) {
      setIsOpenModal(true);
      setContentModal(
        <EditApartment
          itemForEdit={itemForEdit}
          setIsOpenModal={setIsOpenModal}
          render={render}
          setRender={setRender}
        />
      );
    }
  }, [itemForEdit]);

  useEffect(() => {
    if (isOpenModal === false) {
      setItemForEdit(undefined);
    }
  }, [isOpenModal]);

  return (
    <div className="myApartment">
      {isOpenModal && (
        <Modal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          content={contentModal}
        />
      )}

      <PostToServerLoading
        render={render}
        route={url}
        data={setApartments}
        content={
          apartments.length > 0 ? (
            apartments
              .sort((a, b) => (new Date(b.date) < new Date(a.date) ? -1 : 1))
              .map((item, index) => (
                <Accordion
                  key={index}
                  index={index}
                  boxheader={
                    <div className="header_myapartment">
                      <div className="nameApartment">
                        <h1>
                          {item.model === "1"
                            ? "השכרה"
                            : item.model === "2"
                            ? "החלפה"
                            : "השכרה / החלפה"}
                          ,
                        </h1>
                        <h2> {item.areaSearch}.</h2>
                      </div>

                      <div
                        className="show"
                        style={{
                          color:
                            item.show === "0"
                              ? "blue"
                              : item.show === "1"
                              ? "green"
                              : "red",
                        }}
                      >
                        {item.show === "0"
                          ? "ממתין לאישור"
                          : item.show === "1"
                          ? "מודעה פעילה"
                          : "מודעה לא פעילה"}
                      </div>
                    </div>
                  }
                  box={
                    <div style={{ marginBottom: "80px" }}>
                      <SearchApartmentShowComponent
                        data={item}
                        sendMessage={false}
                      />

                      <div className="btnsbottom">
                        <Button
                          title={"ערוך מודעה"}
                          padding={mql.matches ? "0.5rem" : "0.5rem 5.5rem"}
                          borderRadius={"10px"}
                          onClick={() => {
                            setItemForEdit(item);
                          }}
                        />

                        <RmoveApartment
                          idForApartment={item._id}
                          render={render}
                          setRender={setRender}
                          setIsOpenModal={setIsOpenModal}
                          setContentModal={setContentModal}
                        />

                        <HoldApartment
                          idForApartment={item._id}
                          show={item.show}
                          render={render}
                          setRender={setRender}
                          setIsOpenModal={setIsOpenModal}
                          setContentModal={setContentModal}
                        />
                      </div>
                    </div>
                  }
                />
              ))
          ) : (
            <div
              style={{
                textAlign: "center",
                fontSize: "1.6rem",
                margin: 20,
                fontWeight: "bold",
              }}
            >
              אין לך עדיין מודעות חיפוש
            </div>
          )
        }
      />
    </div>
  );
};
export default ItemApartment;
